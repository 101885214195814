import type Pubnub from "pubnub";

const chatMessageDecrypt = (
  pubnub: Pubnub,
  secureText: string,
  cipherKey: string,
) => {
  let openTextObj = '';
  if (!pubnub.cipherKey) {
    pubnub.setCipherKey(cipherKey);
  }
  try {
    openTextObj = pubnub.decrypt(secureText);
    if (!openTextObj) {
      // @ts-ignore
      pubnub._config.useRandomIVs = false;
      openTextObj = pubnub.decrypt(secureText);
      // @ts-ignore
      pubnub._config.useRandomIVs = true;
    }
  } catch (error) {
    console.error("Decryption error:", error);
    return '';
  }
  return openTextObj;
};

export default chatMessageDecrypt;

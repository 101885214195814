import { getErrorDetail } from "components/utility/utils";
import { useUser } from "contexts/UserContext";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useChatHistory } from "./hooks/use-chat-history";
import { usePresence } from "./hooks/use-presence";
import { ChatOperations, PrivateChatSession, PubNubUser } from "./pubnub.types";
import notificationsReducer from "./state/notifications-reducer";
import partnersReducer from "./state/partners-reducer";
import findChatAccessDataByCognitoSub from "./utils/find-chat-access-data-by-cognito-sub";

import { usePrivateAPIData } from "contexts/api-data-provider";
import { reportError, reportProblem } from "utils/frontend_error_reporting";
import { useChatAccess } from "./hooks/use-chat-access";
import useChatNotifications from "./hooks/use-chat-notifications";
import useChatOperations from "./hooks/use-chat-operations";
import usePubNubInstance from "./hooks/use-pub-nub-instance";
import { ChatMessageNotificationDataEncrypted } from "./pubnub.types";
import channelsReducer from "./state/channels-reducer";
import usePrivateChats from "./hooks/use-private-chats";
import pubNubTimetokenToDate from "./utils/pubnub-timetoken/pubnub-timetoken-to-date";

interface OrchidPubNubStateContextProps {
  privateChats: Map<string, PrivateChatSession>;
  onlineStatus: Set<string>;
  pubNubUser: PubNubUser | null;
  chatOperations: ChatOperations;
  chatError?: string;
  oldestMessageDatetimeByPartnerSub: Map<string, number>;
}

export const OrchidPubNubStateContext =
  createContext<OrchidPubNubStateContextProps>(
    {} as OrchidPubNubStateContextProps,
  );

interface OrchidPubNubStateProviderPropsProps {
  children: ReactNode;
}

export function OrchidPubNubStateProvider({
  children,
}: OrchidPubNubStateProviderPropsProps) {
  const [notifications, notificationsDispatch] = useReducer(
    notificationsReducer,
    new Set<string>(),
  );
  const [partnerCognitoSubs, partnersDispatch] = useReducer(
    partnersReducer,
    new Set<string>(),
  );

  const [newChatsByChannel, channelsDispatch] = useReducer(channelsReducer, {});

  const { user } = useUser();
  const userSub = user?.sub;

  const [currentPartnerSub, setCurrentPartnerSub] = useState<
    string | undefined
  >(undefined);

  const { onlineStatus } = usePresence();

  const { pubNubInstance, pubNubError } = usePubNubInstance();

  const [
    unreadConversationsUpdateTimestamp,
    setUnreadConversationsUpdateTimestamp,
  ] = useState<Date>(new Date());

  const {
    unread_conversations: {
      result: unreadConversations,
      errorText: unreadConversationsError,
    },
  } = usePrivateAPIData(
    {
      unread_conversations: {
        resource: "/api/users/v1/contacts/unread_conversations",
      },
    },
    unreadConversationsUpdateTimestamp.getTime(),
  );

  const allPartnerCognitoSubs = useMemo(() => {
    return new Set(
      Array.from(partnerCognitoSubs).concat(
        (unreadConversations || []).map((u) => u.partner_cognito_sub),
      ),
    );
  }, [partnerCognitoSubs, unreadConversations]);

  const { chatAccessDataByChannel, chatAccessError } = useChatAccess(
    pubNubInstance,
    allPartnerCognitoSubs,
    currentPartnerSub,
  );

  useEffect(() => {
    let timeout: null | ReturnType<typeof setTimeout> = null;
    for (const channelData of Object.values(newChatsByChannel)) {
      const access = Array.from(chatAccessDataByChannel.values()).find(
        (session) => session.channel === channelData.channel,
      );
      const cipherKey = access?.cipherKey;
      if (!cipherKey) {
        // TODO: IMPROVE complain if no cipher key found for long
        const msSinceLastUpdate =
          new Date().getTime() - unreadConversationsUpdateTimestamp.getTime();
        const sleepMs = Math.max(0, 10000 - msSinceLastUpdate);
        setTimeout(() => {
          setUnreadConversationsUpdateTimestamp(new Date());
        }, sleepMs);
      }
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [
    newChatsByChannel,
    chatAccessDataByChannel,
    unreadConversationsUpdateTimestamp,
  ]);

  useChatNotifications(
    userSub,
    newChatsByChannel,
    chatAccessDataByChannel,
    pubNubInstance,
    channelsDispatch,
  );

  const onNewMessage = useCallback(
    (
      channel: string,
      messageEncrypted: ChatMessageNotificationDataEncrypted,
    ) => {
      channelsDispatch({
        type: "new_messages",
        channel,
        messagesEncrypted: [messageEncrypted],
      });
    },
    [],
  );

  const handleMessageAction = useCallback((messageActionEvent) => {
    const { channel, action, event } = messageActionEvent; 
    const { type, value, messageTimetoken, actionTimetoken } = action; 
    const sender = value;

    switch (type) {
      case "receipt":
        channelsDispatch({
          type: "message_read_confirm",
          channel,
          messageTimetoken,
          cognitoSub: sender,
          timestamp: pubNubTimetokenToDate(
            actionTimetoken,
          ),
        });
        break;
      case "reaction":
        const reaction =
          event === "removed"
            ? null
            : {
                actionTimetoken,
                emoji: value,
              };

        channelsDispatch({
          type: "set_message_reaction",
          sender,
          channel,
          messageTimetoken,
          reaction,
        });
        break;
      default:
        reportProblem({
          reporter_module: "useOrchidPubNub/handleMessageAction",
          context: {},
          frontend_error: `unknown, unhandled message entry type: ${type}`,
        });
    }
  }, []);

  const handleNewMessage = useCallback(
    (messageEvent) => {
      let {message} = messageEvent;
      if (message && typeof message === 'string' && message.startsWith('{') && message.endsWith('}')) {
        message = JSON.parse(message);
      }
      onNewMessage(messageEvent.channel, {
        msgUniqueId: messageEvent.userMetadata.msg_unique_id || "",
        sender: messageEvent.userMetadata.sender_cognito_sub,
        messageTimetoken: messageEvent.timetoken,
        contentEncrypted: message?.content,
        file: undefined,
        reaction: null,
        receiptsByCognitoSub: {},
      });
    },
    [onNewMessage],
  );

  const handleNewFileEvent = useCallback(
    (fileEvent) => {
      /* TODO make a file metadata provider?
    const listFilesResult = await pubnub.obj.listFiles({channel: fileEvent.channel});
    const fileMetaData = listFilesResult.data.find((e) => e.id === fileEvent.file.id);*/
      let {message} = fileEvent;
      if (message && typeof message === 'string' && message.startsWith('{') && message.endsWith('}')) {
        message = JSON.parse(message);
      }
      onNewMessage(fileEvent.channel, {
        msgUniqueId: fileEvent.userMetadata.msg_unique_id || "",
        sender: fileEvent.userMetadata.sender_cognito_sub,
        messageTimetoken: fileEvent.timetoken,
        contentEncrypted: message?.content,
        file: {
          fileName: fileEvent.file.name,
          fileId: fileEvent.file.id,
          // TODO size: fileMetaData.size,
        },
        reaction: null,
        receiptsByCognitoSub: {},
      });
    },
    [onNewMessage],
  );

  useEffect(() => {
    if (!pubNubInstance || !userSub) return;

    const accessData = Array.from(chatAccessDataByChannel.values()).find(
      (data) => data.partnerCognitoSub === currentPartnerSub
    );
    if (accessData) {
      pubNubInstance.setToken(accessData.token);
      pubNubInstance.setCipherKey(accessData.cipherKey);
    }

    // const channel_type = messageEvent.channel.split('.')[0];
    const pubnubListeners = {
      message: (messageEvent) => {
        try {
          handleNewMessage(messageEvent);
        } catch (err) {
          console.error('Error while listening: ' + err);
          reportError({
            reporter_module: "useOrchidPubNub/pubnubListeners/message",
            context: {},
            err,
          });
        }
      },
      messageAction: (messageActionEvent) => {
        try {
          handleMessageAction(messageActionEvent);
        } catch (err) {
          reportError({
            reporter_module: "useOrchidPubNub/pubnubListeners/message",
            context: {},
            err,
          });
        }
      },
      file: (fileEvent) => {
        try {
          handleNewFileEvent(fileEvent);
        } catch (err) {
          reportError({
            reporter_module: "useOrchidPubNub/pubnubListeners/message",
            context: {},
            err,
          });
        }
      },
    };

    // PubNub receives messages subscribed by the channel groups
    pubNubInstance.addListener(pubnubListeners);

    (async () => {
      try {
        // Subscribe on private chats
        await pubNubInstance.subscribe({
          channels: accessData?.channel ? [accessData.channel] : undefined,
          channelGroups: accessData?.channel ? undefined : [`privatechats_${userSub}`],
          withPresence: false,
        });
      } catch (e) {
        console.error(e);
        await reportProblem({
          reporter_module: "OrchidPubNubStateContext/pubnub_subcribscribe",
          context: {},
          frontend_error: "PubNub subscribe error",
        });
      }
    })();

    return () => {
      if (!pubNubInstance) {
        return;
      }
      pubNubInstance.removeListener(pubnubListeners);
      pubNubInstance.unsubscribeAll();
    };
  }, [
    pubNubInstance,
    userSub,
    handleNewMessage,
    handleMessageAction,
    handleNewFileEvent,
    chatAccessDataByChannel,
    currentPartnerSub
  ]);

  const channelLoadHistoryPriority = currentPartnerSub
    ? findChatAccessDataByCognitoSub(chatAccessDataByChannel, currentPartnerSub)
        ?.channel || null
    : null;

  const {
    chatHistory,
    oldestMessageDatetimeByPartnerSub,
    loadOlderMessagesForPartner,
  } = useChatHistory(
    pubNubInstance,
    chatAccessDataByChannel,
    channelLoadHistoryPriority,
    userSub,
  );

  const pubNubUser = useMemo<PubNubUser | null>(() => {
    if (!user) return null;
    return {
      displayName: `${user.first_name} ${user.last_name}`,
      cognitoSub: user.sub,
    };
  }, [user]);

  const { privateChats } = usePrivateChats(
    userSub,
    newChatsByChannel,
    onlineStatus,
    notifications,
    chatAccessDataByChannel,
    chatHistory,
  );

  //console.log("MEOW", newChatsByChannel, chatHistory, privateChats);

  useEffect(() => {
    partnersDispatch({ type: "reset" });
    notificationsDispatch({ type: "reset" });
  }, [userSub]);

  useEffect(() => {
    if (!unreadConversations) return;
    notificationsDispatch({
      type: "add",
      channels: new Set(unreadConversations.map((u) => u.channel)),
    });

    //TODO: Make sure latest message is still displayed in contact item after receiving a new message when not in focus

    // const partnerSubs = unreadConversations.map((u) => u.partner_cognito_sub);
    // privateChatsNeedLatestHistoryDispatch({
    //   type: "add",
    //   partners: new Set(partnerSubs),
    // });
  }, [unreadConversations]);

  const chatOperations = useChatOperations({
    pubNubInstance,
    userSub,
    privateChats,
    chatAccessDataByChannel,
    partnersDispatch,
    notificationsDispatch,
    setCurrentPartnerSub,
    loadOlderMessagesForPartner,
  });

  return (
    <OrchidPubNubStateContext.Provider
      value={{
        pubNubUser, // TODO: IMPROVE pubNubUser provided for backward compatibility, perhaps could be eliminated?
        onlineStatus,
        privateChats,
        chatOperations,
        chatError:
          pubNubError ||
          chatAccessError ||
          getErrorDetail(unreadConversationsError),
        oldestMessageDatetimeByPartnerSub,
      }}
    >
      {children}
    </OrchidPubNubStateContext.Provider>
  );
}

export const useOrchidPubNubState = () => {
  return useContext(OrchidPubNubStateContext);
};

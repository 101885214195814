import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

export default async function login(
  usernameOrEmail: string,
  password: string,
  mfaCode?: string,
) {
  const response = await fetchOrchidAPI("/api/login/v1/initiate_auth", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username_or_email: usernameOrEmail.trim(),
      password: password,
      mfa_code: mfaCode,
    }),
  });
  if (!response.ok) {
    const responseText = await response.text();
    const errorDetail = getErrorDetail(responseText);
    if (
      response.status === 400 &&
      errorDetail === "MFA code is required for authentication"
    ) {
      return {
        nextStep: "mfa",
      };
    }

    throw new Error(getErrorDetail(responseText));
  }

  return { nextStep: "success" };
}

/**
 * Note: this code is duplicated in marketing-page/src/utils/amplitude.ts
 * We should pull this code out into its own package or better yet, move the
 * code from marketing-page into this repo.
 */
import * as amplitude from "@amplitude/analytics-browser";
import { IS_DEV_ENV } from "constants/isDevEnv";

type AmplitudeUserAction =
  | "click"
  | "hover"
  | "toggle"
  | "keypress"
  | "scroll"
  | "drop"
  | "focus"
  | "visit" // Recorded when a user lands on a page
  | "view"; // Recorded when a user leaves a page, and includes time spent on the page

export const PRO_CALENDAR_ANALYTICS_PAGE_NAME: AmplitudePageName =
  "pro-calendar";

export type AmplitudePageName =
  | "blog-landing-page"
  | "blog-post"
  | "post-blog-page"
  | "pressroom"
  | "press"
  | "admin-post-blog-page"
  | "member-landing-page"
  | "pro-landing-page"
  | "pro-verification-email-page"
  | "pro-welcome-to-orchid-page"
  | "pro-new-quick-intake-page"
  | "pro-welcome-subscribe-page"
  | "post-stripe-connect-page"
  | "pro-dashboard-home"
  | "pro-subscription-checkout"
  | "privacy-policy"
  | "ai-privacy-policy"
  | "terms-of-use"
  | "acceptable-use-policy"
  | "about-us-page"
  | "common-navigation"
  | "pro-calendar"
  | "pro-group-sessions-page"
  | "pro-notes-and-forms-page"
  | "pro-business-analytics"
  | "pro-orchid-storage"
  | "write-for-orchid-page"
  | "pro-invite-to-orchid-page"
  | "pro-settings-profile-page"
  | "pro-settings-practice-settings"
  | "pro-settings-client-portal"
  | "pro-settings-billing"
  | "pro-settings-directory"
  | "pro-settings-ai"
  | "pro-settings-patient-analytics"
  | "pro-settings-account"
  | "pro-settings-notifications"
  | "pro-settings-integration"
  | "pro-update-payment-method-page"
  | "pro-profile-page"
  | "professional-search-page"
  | "pro-registration-page"
  | "change-plan-page"
  | "confirm-change-plan-page"
  | "cancel-plan-page"
  | "note-template-editor"
  | "new-template-editor"
  | "group-session-editor"
  | "pricing-page"
  | "view-receipt-page"
  | "view-superbill-page"
  | "invoice-page"
  | "patient-charts"
  | "patient-chart-client-info"
  | "patient-chart-notes"
  | "patient-chart-medication"
  | "patient-chart-forms-&-portal"
  | "patient-chart-measurements"
  | "patient-chart-attachments"
  | "patient-chart-treatment-plans"
  | "pro-public-profile-page"
  | "form-fill-in-page"
  | "note-fill-in-page"
  | "client-portal-request-approved"
  | "approve-client-page"
  | "add-client-payment-page"
  | "messaging-page"
  | "confirm-all-attendance-page"
  | "waiver-of-liability"
  | "healthcare-provider-payment-policies"
  | "independent-contractor-agreement"
  | "patient-chart-billing-&-insurance"
  | "video-pre-join-page"
  | "practice-group-page"
  | "switch-to-orchid-page"
  | "savings-calculator-page"
  | "orchid-ai-page"
  | "faq-page"
  | "ehr-security-page"
  | "ai-home"
  | "measurement-based-care-page"
  | "partner-with-orchid"
  | "ai-session-review"
  | "ai-patient-chart-client-info"
  | "ai-patient-chart-notes"
  | "ai-patient-chart-forms-&-portal"
  | "mfa-setup";

// Pages disabled from tracking per HIPAA concerns from Joe. Once a BAA is
// signed with Amplitude, we can turn these back on.
const TRACKING_DISABLED_PAGES: AmplitudePageName[] = [
  "patient-chart-client-info",
  "patient-chart-notes",
  "patient-chart-medication",
  "patient-chart-billing-&-insurance",
  "invoice-page",
  "patient-chart-forms-&-portal",
  "patient-chart-attachments",
  "patient-chart-measurements",
  "view-receipt-page",
  "add-client-payment-page",
  "messaging-page",
];

/**
 * When a user logs in, identify the user with amplitude so that following events
 * are associated with that user
 */
export const setAmplitudeUser = (
  userId: string,
  userProperties: {
    isTestAccount?: boolean;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email: string;
    businessName?: string | null;
    userType: string;
  },
) => {
  amplitude.setUserId(userId);
  const event = new amplitude.Identify();
  event.set("is-test-account", userProperties.isTestAccount || IS_DEV_ENV);
  event.set("first-name", userProperties.firstName || "undefined");
  event.set("last-name", userProperties.lastName || "undefined");
  event.set("full-name", userProperties.fullName || "undefined");
  event.set("email", userProperties.email);
  event.set("business-name", userProperties.businessName || "undefined");
  event.set("user-type", userProperties.userType);
  amplitude.identify(event);
};

/**
 * Standard method to call when you want to track an event and send to Amplitude
 * Events are strings that look like "<page-name> / <identifying-component> / <user-action>"
 */
export const track = (
  pageName: AmplitudePageName,
  eventInput: string | string[] | amplitude.Types.BaseEvent,
  userAction: AmplitudeUserAction,
  options?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventProperties?: Record<string, any> | undefined; // Amplitude's definition
    eventOptions?: amplitude.Types.EventOptions | undefined;
  },
) => {
  if (TRACKING_DISABLED_PAGES.includes(pageName)) {
    return;
  }

  let eventInputToSend: string | amplitude.Types.BaseEvent;
  if (Array.isArray(eventInput)) {
    eventInputToSend = [...(eventInput as string[]), userAction].join(" / ");
  } else {
    eventInputToSend = `${eventInput} / ${userAction}`;
  }

  eventInputToSend = `${pageName} / ${eventInputToSend}`;

  const eventPropertiesToSend = {
    // you can add additional properties here
    "is-test-account": IS_DEV_ENV,
    ...options?.eventProperties,
  };

  amplitude.track(
    eventInputToSend,
    eventPropertiesToSend,
    options?.eventOptions,
  );
};

const debounce = (func: (...arg0: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * Use this if your user action may trigger many (duplicate) events in rapid succession
 * which could happen with something like a scroll event
 */
export const debouncedTrack: (
  pageName: AmplitudePageName,
  eventInput: string | string[] | amplitude.Types.BaseEvent,
  userAction: AmplitudeUserAction,
  options?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventProperties?: Record<string, any> | undefined; // Amplitude's definition
    eventOptions?: amplitude.Types.EventOptions | undefined;
  },
) => void = debounce(
  (pageName, eventInput, userAction, options) =>
    track(pageName, eventInput, userAction, {
      eventProperties: options?.eventProperties,
      eventOptions: options?.eventOptions,
    }),
  1000,
);

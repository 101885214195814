import DOMPurify from "dompurify";
import type PubNub from "pubnub";
import { reportProblem } from "utils/frontend_error_reporting";
import { ChatMessageData } from "../pubnub.types";
import chatMessageDecrypt from "../utils/chat-message-decrypt";
import pubNubTimetokenToDate from "../utils/pubnub-timetoken/pubnub-timetoken-to-date";

interface FetchPubnubHistoryArgs {
  pubNubInstance: PubNub;
  channel: string;
  privateChatCipherKey: string;
  onlyOlderThanTimetoken?: string;
  onlyNewerThanTimetoken?: string;
  limit?: number;
}

const fetchPubnubHistory = async ({
  pubNubInstance,
  channel,
  privateChatCipherKey,
  onlyOlderThanTimetoken,
  onlyNewerThanTimetoken,
  limit,
}: FetchPubnubHistoryArgs): Promise<Array<ChatMessageData>> => {
  // console.time(`fetch pubnub history ${channel}`);
  if (!pubNubInstance.cipherKey) {
    pubNubInstance.setCipherKey(privateChatCipherKey)
  }
  const fetchMessagesPromise = pubNubInstance.fetchMessages({
    channels: [channel],
    start: onlyOlderThanTimetoken,
    end: onlyNewerThanTimetoken,
    count: limit,
    includeMessageActions: true,
    includeMeta: true,
  });

  const listFilesPromise = pubNubInstance.listFiles({ channel });

  const [fetchMessagesResult, listFilesResult] = await Promise.all([
    fetchMessagesPromise,
    listFilesPromise,
  ]);
  // console.timeEnd(`fetch pubnub history ${channel}`);

  const messages: Array<ChatMessageData> = [];
  const channelResult = fetchMessagesResult.channels[channel] || [];
  if (channelResult) {
    for (const entry of channelResult) {
      // assume there is only one reaction
      const reactionEmoji =
        entry.actions && entry.actions.reaction
          ? Object.keys(entry.actions.reaction)[0]
          : null;
      const reaction = reactionEmoji
        ? {
            actionTimetoken:
              entry.actions.reaction[
                reactionEmoji
              ][0].actionTimetoken.toString(),
            emoji: reactionEmoji,
          }
        : null;
      let message = entry.message.file
        ? entry.message?.message
        : entry.message;
      let contentSanitized: string | undefined = undefined;
      if (message && typeof message === 'string' && message.startsWith('{') && message.endsWith('}')) {
        message = JSON.parse(message);
      }
      if (!message?.content) {
        const msg = chatMessageDecrypt(
              pubNubInstance,
              message,
              privateChatCipherKey,
            );
        if (!msg && !entry.message.file) {
          await reportProblem({
            reporter_module: "OrchidPubNubStateContext/pubnub_fetch_history",
            context: {},
            frontend_error: "failed to decrypt message entry",
          });
          continue;
        }
        contentSanitized = DOMPurify.sanitize(msg.content) as string;
      } else {
        contentSanitized = DOMPurify.sanitize(message.content) as string;
      }
      const sender = entry.meta?.sender_cognito_sub;
      if (!sender) {
        await reportProblem({
          reporter_module: "OrchidPubNubStateContext/pubnub_fetch_history",
          context: {},
          frontend_error: "no sender metadata in entry",
        });
        continue;
      }

      const receiptsByCognitoSub = {};
      if (entry?.actions?.receipt) {
        for (const [cognitoSub, d] of Object.entries(entry.actions.receipt)) {
          receiptsByCognitoSub[cognitoSub] = pubNubTimetokenToDate(
            d[0].actionTimetoken.toString(),
          );
        }
      }

      if (entry.message.file) {
        const fileMetaData = listFilesResult.data.find(
          (e) => e.id === entry.message.file.id,
        );
        messages.push({
          msgUniqueId: entry?.meta?.msg_unique_id || "",
          sender,
          messageTimetoken: entry.timetoken.toString(),
          content: contentSanitized,
          file: {
            fileName: `${entry.message.file.name}`,
            fileId: entry.message.file.id,
            size: fileMetaData?.size,
          },
          reaction,
          receiptsByCognitoSub,
        });
      } else if (entry.messageType === -1 || entry.messageType === null) {
        messages.push({
          msgUniqueId: entry.meta?.msg_unique_id || "",
          sender,
          messageTimetoken: entry.timetoken.toString(),
          content: contentSanitized,
          file: undefined,
          reaction: reaction,
          receiptsByCognitoSub,
        });
      } else {
        await reportProblem({
          reporter_module: "OrchidPubNubStateContext/pubnub_fetch_history",
          context: {},
          frontend_error: "unknown, unhandled message entry",
        });
      }
    }
  }

  return messages;
};

export default fetchPubnubHistory;

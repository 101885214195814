import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export enum SidesheetType {
  SessionDetails = "session-details",
  EditSession = "edit-session",
  GcalEventDetails = "gcal-event-details",
  AddToCalendar = "add-to-calendar",
  BlockedIntervalDetails = "blocked-interval-details",
  EditBlockedInterval = "edit-blocked-interval",
  DayPreview = "day-preview",
  EditAvailabilityPeriod = "edit-availability-period",
  None = "none",
}

export enum MobileSettingsScreenType {
  Availability = "availability",
  SessionTypes = "session-types",
  CancellationPolicy = "cancellation-policy",
  None = "none",
}

export interface CalendarSidebarState {
  currentSidesheet: SidesheetType;

  focusedSessionAppointment:
    | (AppointmentWithSeriesInfo & {
        invoice?: InvoiceData;
      })
    | null;
  editSeriesMode: "this_session" | "all_sessions" | "this_and_future_session";
  focusedSessionNextStatus: string | null;

  focusedGcalEvent: CalendarBlockedInterval | null;

  newSessionHasInitialTime: boolean;
  newSessionDefaultStartTime: number;
  newSessionPlaceholder: SessionPlaceholder | null;

  focusedBlockedInterval: CalendarBlockedInterval | null;
  editedBlockedIntervalPreview: CalendarBlockedInterval | null;

  focusedDayStartValue: number;
  appointmentsWithInvoiceCreationInProgress: number[];

  editedAvailabilityPeriodData: AvailableTimePeriod | null;
  editedAvailabilityPeriodIndex: number;

  isMobileSidebarVisible: boolean;
  currentMobileSettingsScreen: MobileSettingsScreenType;
}

const initialState: CalendarSidebarState = {
  currentSidesheet: SidesheetType.None,

  focusedSessionAppointment: null,
  editSeriesMode: "this_session",
  focusedSessionNextStatus: null,

  focusedGcalEvent: null,

  newSessionHasInitialTime: false,
  newSessionDefaultStartTime: 0,
  newSessionPlaceholder: null,

  focusedBlockedInterval: null,
  editedBlockedIntervalPreview: null,

  focusedDayStartValue: 0,
  appointmentsWithInvoiceCreationInProgress: [],

  editedAvailabilityPeriodData: null,
  editedAvailabilityPeriodIndex: -1,

  isMobileSidebarVisible: false,
  currentMobileSettingsScreen: MobileSettingsScreenType.None,
};

export const calendarSlice = createSlice({
  name: "calendarSidebar",
  initialState,
  reducers: {
    showSessionDetails: (
      state,
      action: PayloadAction<{
        appointment: AppointmentWithSeriesInfo & { invoice?: InvoiceData };
        nextStatus: string | null;
      }>,
    ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const { appointment, nextStatus } = action.payload;
      state.currentSidesheet = SidesheetType.SessionDetails;
      state.focusedSessionAppointment = appointment;
      state.editSeriesMode = "this_session";
      state.focusedSessionNextStatus = nextStatus;
      state.newSessionPlaceholder = null;
    },
    showEditSession: (
      state,
      action: PayloadAction<{
        appointment: AppointmentWithSeriesInfo & { invoice?: InvoiceData };
        editSeriesMode:
          | "this_session"
          | "all_sessions"
          | "this_and_future_session";
      }>,
    ) => {
      const { appointment, editSeriesMode } = action.payload;
      state.currentSidesheet = SidesheetType.EditSession;
      state.focusedSessionAppointment = appointment;
      state.editSeriesMode = editSeriesMode;
      state.newSessionPlaceholder = null;
    },
    setFocusedSession: (
      state,
      action: PayloadAction<
        AppointmentWithSeriesInfo & { invoice?: InvoiceData }
      >,
    ) => {
      state.focusedSessionAppointment = action.payload;
    },
    setFocusedSessionNextStatus: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.focusedSessionNextStatus = action.payload;
    },

    showGcalEventDetails: (
      state,
      action: PayloadAction<CalendarBlockedInterval>,
    ) => {
      state.currentSidesheet = SidesheetType.GcalEventDetails;
      state.focusedGcalEvent = action.payload;
      state.newSessionPlaceholder = null;
    },

    showAddToCalendar: (
      state,
      action: PayloadAction<{
        hasInitialTime: boolean;
        defaultStartTime: number;
      }>,
    ) => {
      const { hasInitialTime, defaultStartTime } = action.payload;
      state.currentSidesheet = SidesheetType.AddToCalendar;

      state.newSessionHasInitialTime = hasInitialTime;
      state.newSessionDefaultStartTime = defaultStartTime;
    },

    setNewSessionPlaceholder: (
      state,
      action: PayloadAction<SessionPlaceholder | null>,
    ) => {
      state.newSessionPlaceholder = action.payload;
    },

    showBlockIntervalDetails: (
      state,
      action: PayloadAction<CalendarBlockedInterval>,
    ) => {
      state.currentSidesheet = SidesheetType.BlockedIntervalDetails;
      state.focusedBlockedInterval = action.payload;
      state.newSessionPlaceholder = null;
    },

    showEditBlockInterval: (
      state,
      action: PayloadAction<CalendarBlockedInterval>,
    ) => {
      state.currentSidesheet = SidesheetType.EditBlockedInterval;
      state.focusedBlockedInterval = action.payload;
      state.editedBlockedIntervalPreview = action.payload;
      state.newSessionPlaceholder = null;
    },

    setEditedBlockedIntervalPreview: (
      state,
      action: PayloadAction<CalendarBlockedInterval>,
    ) => {
      state.editedBlockedIntervalPreview = action.payload;
    },

    showDayPreview: (state, action: PayloadAction<number>) => {
      state.currentSidesheet = SidesheetType.DayPreview;
      state.focusedDayStartValue = action.payload;
      state.newSessionPlaceholder = null;
    },

    closeSidesheet: (state) => {
      state.currentSidesheet = SidesheetType.None;
      state.newSessionPlaceholder = null;
    },

    addAppointmentWithInvoiceCreationInProgress: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.appointmentsWithInvoiceCreationInProgress = [
        ...state.appointmentsWithInvoiceCreationInProgress,
        action.payload,
      ];
    },

    removeAppointmentWithInvoiceCreationInProgress: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.appointmentsWithInvoiceCreationInProgress =
        state.appointmentsWithInvoiceCreationInProgress.filter(
          (id) => id !== action.payload,
        );
    },

    showMobileSidebar: (state) => {
      state.isMobileSidebarVisible = true;
    },

    hideMobileSidebar: (state) => {
      state.isMobileSidebarVisible = false;
      state.currentMobileSettingsScreen = MobileSettingsScreenType.None;
    },

    showCancellationPolicyScreen: (state) => {
      state.currentMobileSettingsScreen =
        MobileSettingsScreenType.CancellationPolicy;
    },
    showSessionTypesScreen: (state) => {
      state.currentMobileSettingsScreen = MobileSettingsScreenType.SessionTypes;
    },
    showAvailabilityScreen: (state) => {
      state.currentMobileSettingsScreen = MobileSettingsScreenType.Availability;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showSessionDetails,
  showEditSession,
  setFocusedSession,
  setFocusedSessionNextStatus,

  showGcalEventDetails,

  showAddToCalendar,
  setNewSessionPlaceholder,

  showBlockIntervalDetails,
  showEditBlockInterval,
  setEditedBlockedIntervalPreview,

  showDayPreview,

  closeSidesheet,
  addAppointmentWithInvoiceCreationInProgress,
  removeAppointmentWithInvoiceCreationInProgress,

  showMobileSidebar,
  hideMobileSidebar,

  showCancellationPolicyScreen,
  showSessionTypesScreen,
  showAvailabilityScreen,
} = calendarSlice.actions;

export const selectCurrentSidesheet = (state: RootState) =>
  state.calendarSidebar.currentSidesheet;

export const selectFocusedSessionAppointment = (state: RootState) =>
  state.calendarSidebar.currentSidesheet === SidesheetType.SessionDetails ||
  state.calendarSidebar.currentSidesheet === SidesheetType.EditSession
    ? state.calendarSidebar.focusedSessionAppointment
    : null;
export const selectEditSeriesMode = (state: RootState) =>
  state.calendarSidebar.currentSidesheet === SidesheetType.SessionDetails ||
  state.calendarSidebar.currentSidesheet === SidesheetType.EditSession
    ? state.calendarSidebar.editSeriesMode
    : "this_session";
export const selectFocusedSessionNextStatus = (state: RootState) =>
  state.calendarSidebar.currentSidesheet === SidesheetType.SessionDetails
    ? state.calendarSidebar.focusedSessionNextStatus
    : null;

export const selectFocusedGcalEvent = (state: RootState) =>
  state.calendarSidebar.currentSidesheet === SidesheetType.GcalEventDetails
    ? state.calendarSidebar.focusedGcalEvent
    : null;

export const selectNewSessionHasInitialTime = (state: RootState) =>
  state.calendarSidebar.newSessionHasInitialTime;
export const selectNewSessionDefaultStartTime = (state: RootState) =>
  state.calendarSidebar.newSessionDefaultStartTime;
export const selectNewSessionPlaceholder = (state: RootState) =>
  state.calendarSidebar.newSessionPlaceholder;

export const selectFocusedBlockedInterval = (state: RootState) =>
  state.calendarSidebar.currentSidesheet ===
    SidesheetType.BlockedIntervalDetails ||
  state.calendarSidebar.currentSidesheet === SidesheetType.EditBlockedInterval
    ? state.calendarSidebar.focusedBlockedInterval
    : null;

export const selectEditedBlockedIntervalPreview = (state: RootState) =>
  state.calendarSidebar.currentSidesheet === SidesheetType.EditBlockedInterval
    ? state.calendarSidebar.editedBlockedIntervalPreview
    : null;

export const selectFocusedDay = (state: RootState) =>
  state.calendarSidebar.currentSidesheet === SidesheetType.DayPreview
    ? state.calendarSidebar.focusedDayStartValue
    : 0;

export const selectAppointmentsWithInvoiceCreationInProgress = (
  state: RootState,
) => state.calendarSidebar.appointmentsWithInvoiceCreationInProgress;

export const selectIsMobileSidebarVisible = (state: RootState) =>
  state.calendarSidebar.isMobileSidebarVisible;

export const selectCurrentMobileSettingsScreen = (state: RootState) =>
  state.calendarSidebar.currentMobileSettingsScreen;

export default calendarSlice.reducer;

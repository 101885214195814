export const INTEGRATIONS_ENABLED = false;
export const ZOOM_INTEGRATION_ENABLED = false;

export const ENABLE_NEW_TEMPLATE_CONTENT = true;
export const DISABLE_OLD_TEMPLATE_CONTENT = true;
export const USE_SCHEMA_V2_WITH_GRADIENTJ = true;

export const ENABLE_INACTIVITY_LOGOUT = true;
export const ENABLE_TEMPLATE_VALIDATION_BEFORE_SIGNING = true;

export const SUPPORT_GOING_BACK_FROM_SESSION_REVIEW = true;
export const OPEN_NOTE_FROM_CALENDAR_IN_THE_SAME_TAB = true;
export const ADD_NOTE_IN_CALENDAR_REDIRECTS_TO_SESSION_REVIEW = true;

export const ENABLE_PREBUILT_TEMPLATES = true;
export const ENABLE_CHART_NOTES = true;

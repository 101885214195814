import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
// components
import EmailSentModal from "components/auth/EmailSentModal";
import ResetPasswordModal from "components/auth/ResetPasswordModal";
import MemberIntakeFillIn from "components/auth/member-intake-fill-in";
import PatientRegisterModal from "components/auth/PatientRegisterModal";
import LoginModal from "./components/login-modal/LoginModal";

// contexts
import { useUser } from "../UserContext";
// hooks
import useToggleModal from "hooks/useToggleModal";

export type CustomLoginModalProps = {
  isInNavbar?: boolean;
  isInDashboard?: boolean;
};

export type CustomMemberIntakeFormWrapperProps = {
  isMemberIntakeFormIncomplete?: boolean;
  isFinalizePurchased?: boolean;
};

export type CustomEmailSentModalProps = {
  email?: string;
  isEmailUnconfirmed?: boolean;
};

type Context = {
  setCustomLoginModalProps: (arg: CustomLoginModalProps) => void;
  openLoginModal: () => void;
  openResetPasswordModal: () => void;
  setCustomEmailSentModalProps: (arg: CustomEmailSentModalProps) => void;
  openEmailSentModal: () => void;
  setCustomMemberIntakeFormWrapperProps: (
    arg: CustomMemberIntakeFormWrapperProps,
  ) => void;
  openMemberIntakeFormWrapper: () => void;
  openPatientRegisterModal: () => void;
  openAuthModals: () => void;
};

const AuthModalsContext = createContext<Context>({
  setCustomLoginModalProps: () => null,
  openLoginModal: () => null,
  setCustomEmailSentModalProps: () => null,
  openResetPasswordModal: () => null,
  openEmailSentModal: () => null,
  setCustomMemberIntakeFormWrapperProps: () => null,
  openMemberIntakeFormWrapper: () => null,
  openPatientRegisterModal: () => null,
  openAuthModals: () => null,
});
export default AuthModalsContext;

type Props = { children: NonNullable<ReactNode> };

export const AuthModalsContextProvider = ({ children }: Props) => {
  const history = useHistory();
  const { user, updateUserInfo } = useUser();

  // modal controls
  const {
    show: showLoginModal,
    open: openLoginModalOriginal,
    close: closeLoginModal,
  } = useToggleModal(false);

  const {
    show: showResetPasswordModal,
    open: openResetPasswordModalOriginal,
    close: closeResetPasswordModal,
  } = useToggleModal(false);

  const {
    show: showEmailSentModal,
    open: openEmailSentModalOriginal,
    close: closeEmailSentModal,
  } = useToggleModal(false);

  const {
    show: showMemberIntakeFormWrapper,
    open: openMemberIntakeFormWrapperOriginal,
    close: closeMemberIntakeFormWrapper,
  } = useToggleModal(false);

  const {
    show: showPatientRegisterModal,
    open: openPatientRegisterModalOriginal,
    close: closePatientRegisterModal,
  } = useToggleModal(false);

  // open modal and close others
  const openLoginModal = useCallback(() => {
    closeResetPasswordModal();
    closeEmailSentModal();
    closeMemberIntakeFormWrapper();
    closePatientRegisterModal();
    return openLoginModalOriginal();
  }, [
    openLoginModalOriginal,
    closeResetPasswordModal,
    closeEmailSentModal,
    closeMemberIntakeFormWrapper,
    closePatientRegisterModal,
  ]);

  const openResetPasswordModal = useCallback(() => {
    closeLoginModal();
    closeEmailSentModal();
    closeMemberIntakeFormWrapper();
    closePatientRegisterModal();
    return openResetPasswordModalOriginal();
  }, [
    openResetPasswordModalOriginal,
    closeLoginModal,
    closeEmailSentModal,
    closeMemberIntakeFormWrapper,
    closePatientRegisterModal,
  ]);

  const openEmailSentModal = useCallback(() => {
    closeLoginModal();
    closeResetPasswordModal();
    closeMemberIntakeFormWrapper();
    closePatientRegisterModal();
    return openEmailSentModalOriginal();
  }, [
    openEmailSentModalOriginal,
    closeLoginModal,
    closeResetPasswordModal,
    closeMemberIntakeFormWrapper,
    closePatientRegisterModal,
  ]);

  const openMemberIntakeFormWrapper = useCallback(() => {
    closeLoginModal();
    closeResetPasswordModal();
    closeEmailSentModal();
    closePatientRegisterModal();
    return openMemberIntakeFormWrapperOriginal();
  }, [
    openMemberIntakeFormWrapperOriginal,
    closeLoginModal,
    closeResetPasswordModal,
    closeEmailSentModal,
    closePatientRegisterModal,
  ]);

  const openPatientRegisterModal = useCallback(() => {
    closeLoginModal();
    closeResetPasswordModal();
    closeEmailSentModal();
    closeMemberIntakeFormWrapper();
    return openPatientRegisterModalOriginal();
  }, [
    openPatientRegisterModalOriginal,
    closeLoginModal,
    closeResetPasswordModal,
    closeEmailSentModal,
    closeMemberIntakeFormWrapper,
  ]);

  const [customLoginModalProps, setCustomLoginModalProps] =
    useState<CustomLoginModalProps>({
      isInNavbar: false,
      isInDashboard: false,
    });

  const resetCustomLoginModalProps = () =>
    setCustomLoginModalProps({
      isInNavbar: false,
      isInDashboard: false,
    });

  const [
    customMemberIntakeFormWrapperProps,
    setCustomMemberIntakeFormWrapperProps,
  ] = useState<CustomMemberIntakeFormWrapperProps>({
    isMemberIntakeFormIncomplete: true,
    isFinalizePurchased: false,
  });
  const resetCustomMemberIntakeFormWrapperProps = () =>
    setCustomMemberIntakeFormWrapperProps({
      isMemberIntakeFormIncomplete: true,
      isFinalizePurchased: false,
    });
  const [customEmailSentModalProps, setCustomEmailSentModalProps] =
    useState<CustomEmailSentModalProps>({
      email: "",
      isEmailUnconfirmed: false,
    });
  const resetCustomEmailSentModalProps = () =>
    setCustomEmailSentModalProps({
      email: "",
      isEmailUnconfirmed: false,
    });

  // should check missing user props and show modal respectively
  const [showAuthModals, setShowAuthModals] = useState(0);

  // separate logic between user info and show modals
  const validate = useCallback(() => {
    if (!user) {
      return openLoginModal();
    }
    if (!user.email_confirmed) {
      closeLoginModal();
      return openEmailSentModal();
    }

    if (!user.signup_form_finished) {
      if ((user.groups || []).includes("orchid_pro")) {
        closeLoginModal();
        return history.replace("/quick-intake");
      }
      closeLoginModal();
      return openMemberIntakeFormWrapper();
    }
  }, [
    user,
    openLoginModal,
    closeLoginModal,
    openEmailSentModal,
    history,
    openMemberIntakeFormWrapper,
  ]);

  useEffect(() => {
    if (showAuthModals) {
      validate();
    }
  }, [showAuthModals, validate]);

  const openAuthModals = useCallback(
    () => setShowAuthModals((s) => s + 1),
    [setShowAuthModals],
  );

  const value = useMemo(
    () => ({
      setCustomLoginModalProps,
      openLoginModal,
      openResetPasswordModal,
      setCustomEmailSentModalProps,
      openEmailSentModal,
      setCustomMemberIntakeFormWrapperProps,
      openMemberIntakeFormWrapper,
      openPatientRegisterModal,
      openAuthModals,
    }),
    [
      setCustomLoginModalProps,
      openLoginModal,
      openResetPasswordModal,
      setCustomEmailSentModalProps,
      openEmailSentModal,
      setCustomMemberIntakeFormWrapperProps,
      openMemberIntakeFormWrapper,
      openPatientRegisterModal,
      openAuthModals,
    ],
  );
  return (
    <AuthModalsContext.Provider value={value}>
      {/* forgot password modal */}
      <ResetPasswordModal
        show={showResetPasswordModal}
        onHide={closeResetPasswordModal}
      />
      {/* emal sent modal */}
      <EmailSentModal
        show={showEmailSentModal}
        onHide={() => {
          closeEmailSentModal();
          resetCustomEmailSentModalProps();
        }}
        updateUserInfo={updateUserInfo}
        {...customEmailSentModalProps}
      />
      {/* member intake form modal */}
      <MemberIntakeFillIn
        show={showMemberIntakeFormWrapper}
        onHide={() => {
          closeMemberIntakeFormWrapper();
          resetCustomMemberIntakeFormWrapperProps();
        }}
        {...customMemberIntakeFormWrapperProps}
      />
      {/* member register modal */}
      <PatientRegisterModal
        show={showPatientRegisterModal}
        onHide={closePatientRegisterModal}
      />
      {/* login modal */}
      <LoginModal
        show={showLoginModal}
        onHide={() => {
          closeLoginModal();
          resetCustomLoginModalProps();
        }}
        openAuthModals={openAuthModals}
        openResetPasswordModal={openResetPasswordModal}
        openPatientRegisterModal={openPatientRegisterModal}
        setCustomEmailSentModalProps={setCustomEmailSentModalProps}
        {...customLoginModalProps}
      />

      {children}
    </AuthModalsContext.Provider>
  );
};

export const useAuthModals = () => useContext(AuthModalsContext);

import React, { useEffect } from "react";
import { STATE_OPTIONS } from "constants/stateOptions";
import { PROVINCE_OPTIONS } from "constants/provinceOptions";
import { COLOMBIA_DEPARTMENT_OPTIONS } from "constants/departmentOptions";
import { COUNTRY_OPTIONS } from "constants/countryOptions";
import { Form, Col } from "react-bootstrap";
import CustomSelect from "components/custom-select/CustomSelect";
import styles from "./RequiredPanel.module.scss";

const getRegionOptions = (country) => {
  switch (country) {
    case "CA":
      return PROVINCE_OPTIONS;
    case "CO":
      return COLOMBIA_DEPARTMENT_OPTIONS;
    default:
      return STATE_OPTIONS;
  }
};

const getRegionLabel = (country) => {
  switch (country) {
    case "CA":
      return "Province";
    case "CO":
      return "Department";
    default:
      return "State";
  }
};

const getPostalCodeLabel = (country) =>
  country === "CA" || country === "CO" ? "Postal Code" : "Zip";

const RequiredPanel = ({ member, registration, fieldChangedCallback }) => {
  const onInputChange = (event) => {
    const { name, value } = event.currentTarget;
    fieldChangedCallback(name, value);
  };

  useEffect(() => {
    if (!member.country) {
      fieldChangedCallback("country", "US");
    }
  }, [member.country, fieldChangedCallback]);

  return (
    <>
      <Form.Group>
        <Form.Label className={styles["required-panel__form-label"]}>
          Legal First Name <span className="required">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter first name"
          name="first_name"
          value={member.first_name || ""}
          onChange={onInputChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className={styles["required-panel__form-label"]}>
          Legal Last Name <span className="required">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter last name"
          name="last_name"
          value={member.last_name || ""}
          onChange={onInputChange}
        />
      </Form.Group>

      {registration && (
        <Form.Group>
          <Form.Label className={styles["required-panel__form-label"]}>
            Date of Birth <span className="required">*</span>
          </Form.Label>
          <Form.Control
            required
            type="date"
            max={
              new Date(new Date().setFullYear(new Date().getFullYear() - 13))
                .toISOString()
                .split("T")[0]
            }
            pattern="^(0[1-9]|1[012])/(0[1-9]|[12][0-9]|3[01])/(19|20)\d\d$"
            placeholder="mm/dd/yyyy"
            name="dob"
            value={member.dob || ""}
            onChange={onInputChange}
          />
        </Form.Group>
      )}

      <Form.Group>
        <Form.Label className={styles["required-panel__form-label"]}>
          Street Address <span className="required">*</span>
        </Form.Label>
        <Form.Control
          required
          name="home_address_line1"
          value={member.home_address_line1 || ""}
          onChange={onInputChange}
          placeholder="Enter Address"
        />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label className={styles["required-panel__form-label"]}>
            Country <span className="required">*</span>
          </Form.Label>
          <CustomSelect
            options={COUNTRY_OPTIONS}
            onChange={(el) => fieldChangedCallback("country", el.value)}
            required
            value={COUNTRY_OPTIONS.find(
              (option) => option.value === member.country,
            )}
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label className={styles["required-panel__form-label"]}>
            {getRegionLabel(member.country)} <span className="required">*</span>
          </Form.Label>
          <CustomSelect
            options={getRegionOptions(member.country)}
            onChange={(el) => fieldChangedCallback("home_state", el.value)}
            required
            value={getRegionOptions(member.country).find(
              (option) => option.value === member.home_state,
            )}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label className={styles["required-panel__form-label"]}>
            City <span className="required">*</span>
          </Form.Label>
          <Form.Control
            required
            name="home_city"
            value={member.home_city || ""}
            onChange={onInputChange}
            placeholder="Enter City"
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label className={styles["required-panel__form-label"]}>
            {getPostalCodeLabel(member.country)}{" "}
            <span className="required">*</span>
          </Form.Label>
          <Form.Control
            required
            name="home_zipcode"
            value={member.home_zipcode || ""}
            onChange={onInputChange}
            placeholder={`Enter ${getPostalCodeLabel(member.country)}`}
          />
        </Form.Group>
      </Form.Row>
    </>
  );
};

export default RequiredPanel;

import { getErrorDetail } from "components/utility/utils";
import type PubNub from "pubnub";
import { useEffect, useMemo, useRef, useState } from "react";
import { fetchOrchidAPI } from "utils/api";
import { ChatAccessDataMapByChannel } from "../pubnub.types";

export function useChatAccess(
  pubnubInstance: PubNub | null,
  partnerCognitoSubs: Set<string>,
  currentPartnerSub?: string,
): {
  chatAccessDataByChannel: ChatAccessDataMapByChannel;
  chatAccessError?: string;
} {
  const partners = useMemo(() => {
    return Array.from(partnerCognitoSubs);
  }, [partnerCognitoSubs]);

  const [needsRefresh, setNeedsRefresh] = useState(false);

  const grantRequests = useRef<{
    [key: string]: Promise<any>;
  }>({});
  const cipherRequests = useRef<{
    [key: string]: Promise<any>;
  }>({});

  const [grantResults, setGrantResults] = useState<{
    [key: string]: any;
  }>({});
  const [cipherResults, setCipherResults] = useState<{
    [key: string]: any;
  }>({});

  const [grantErrors, setGrantErrors] = useState<{
    [key: string]: string;
  }>({});

  const [cipherErrors, setCipherErrors] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    if (!pubnubInstance?.userId) {
      return;
    }

    if (needsRefresh) {
      grantRequests.current = {};
      cipherRequests.current = {};
      setNeedsRefresh(false);
    }

    partners
      .sort((a, _) => (a === currentPartnerSub ? -1 : 1))
      .forEach((partner) => {
        if (!grantRequests.current[partner]) {
          grantRequests.current[partner] = fetchOrchidAPI(
            "/api/pubnub_grant/v2/grant_private_chat",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                cognito_sub: partner,
              }),
            },
          )
            .then(async (response) => {
              if (!response.ok) {
                const errorText = await response.text();
                setGrantErrors((prev) => ({
                  ...prev,
                  [partner]: errorText,
                }));
                return;
              }
              const resultData = await response.json();
              setGrantResults((prev) => ({ ...prev, [partner]: resultData }));
            })
            .catch((error) =>
              setGrantErrors((prev) => ({
                ...prev,
                [partner]: error.message,
              })),
            );
        }

        if (!cipherRequests.current[partner]) {
          cipherRequests.current[partner] = fetchOrchidAPI(
            "/api/pubnub_cipher/v1/private_chat_cipher",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ cognito_sub: partner }),
            },
          )
            .then(async (response) => {
              if (!response.ok) {
                const errorText = await response.text();
                setCipherErrors((prev) => ({
                  ...prev,
                  [partner]: errorText,
                }));
                return;
              }
              const resultData = await response.json();
              setCipherResults((prev) => ({ ...prev, [partner]: resultData }));
            })
            .catch((error) =>
              setCipherErrors((prev) => ({
                ...prev,
                [partner]: error.message,
              })),
            );
        }
      });
  }, [partners, needsRefresh, currentPartnerSub, pubnubInstance?.userId]);

  const chatAccessDataByChannel = useMemo(() => {
    const chatAccessDataByChannel: ChatAccessDataMapByChannel = new Map();
    for (const partner of partners) {
      const grant = grantResults[partner];
      const cipher = cipherResults[partner];
      if (grant && cipher) {
        chatAccessDataByChannel.set(grant.channel, {
          type: "private",
          channel: grant.channel,
          token: grant.token,
          partnerCognitoSub: partner,
          allowSend: grant.allow_send,
          cipherKey: cipher.private_chat_cipher_key,
        });
      }
    }
    return chatAccessDataByChannel;
  }, [grantResults, cipherResults, partners]);

  const chatAccessError = useMemo(() => {
    const grantErrorText = Object.values(grantErrors).filter((e) => e)[0];
    const cipherErrorText = Object.values(cipherErrors).filter((e) => e)[0];
    return getErrorDetail(grantErrorText || cipherErrorText);
  }, [grantErrors, cipherErrors]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNeedsRefresh(true);
      // }, 30 * 60 * 1000);
    }, 120 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return { chatAccessDataByChannel, chatAccessError };
}

import { STATE_SEARCH_OPTIONS } from "components/utility/option-constants";
import { GENERAL_ROUTES } from "constants/generalRoutes";
import messages from "messages";

export const TWO_WAYS = [
  {
    id: "individualSessions",
    title: messages.individualSessions,
    body: messages.wheneverYourNeed,
  },
  {
    id: "supportGroups",
    title: messages.supportGroups,
    body: "Get all the community, camaraderie, and strength of group sessions — from the comfort of your favorite couch. (Bonus points if there's a fuzzy blanket.)",
  },
];

export const BENEFITS = [
  {
    id: "infiniteFlexibility",
    title: messages.infiniteFlexibility,
    body: messages.wheneverYourNeed,
  },
  {
    id: "instantScheduling",
    title: messages.instantScheduling,
    body: messages.lookAtYourProvider,
  },
  {
    id: "easyCommunication",
    title: messages.easyCommunication,
    body: messages.ditchThePhoneTag,
  },
  {
    id: "aimForAffordability",
    title: messages.aimForAffordability,
    body: messages.pickYourFavoritePayment,
  },
  {
    id: "trackableProgress",
    title: messages.trackableProgress,
    body: messages.withNoExtraHassle,
  },
  {
    id: "aPlaceForEverything",
    title: messages.aPlaceForEverything,
    body: messages.fromFillingOutYourIntake,
  },
];

export const EMERGENCY_LINES = [
  {
    id: "suicidePreventionLifeline",
    title: messages.suicidePreventionLifeline,
    phone: "+1-800-273-8255",
  },
  {
    id: "crisisTextLine",
    title: messages.crisisTextLine,
    body: messages.textHomeTo,
    phone: "741741",
  },
];

const SPECIALTIES = ["Therapist", "Psychologist", "Psychiatrist"];
const stateNameToUrlSegment = (stateName) =>
  stateName.replace(" (DC)", "").toLowerCase().replace(/\s/g, "-");

export interface FooterSectionData {
  title: string;
  links: { title: string; href: string; id: string }[];
  secondaryLevel?: FooterSectionData;
  collapseAfter?: number;
  expandLabel?: string;
}

export const FOOTER_SECTIONS_TOP: FooterSectionData[] = [
  {
    title: messages.forClinicians,
    links: [
      {
        title: messages.scheduleAnIndividualSession,
        href: GENERAL_ROUTES.professionalSearch,
        id: "schedule-individual-session",
      },
      {
        title: messages.findASupportGroup,
        href: GENERAL_ROUTES.groupSession,
        id: "find-a-support-group",
      },
      {
        title: messages.aboutUs,
        href: GENERAL_ROUTES.aboutUs,
        id: "about-us",
      },
      {
        title: messages.press,
        href: GENERAL_ROUTES.press,
        id: "press",
      },
    ],
    secondaryLevel: {
      title: messages.followUs,
      links: [
        // {
        //   title: "Instagram",
        //   href: "https://www.instagram.com/heythereorchid/",
        //   id: "instagram",
        // },
        // {
        //   title: "Facebook",
        //   href: "https://www.facebook.com/Orchid-103015148223006",
        //   id: "facebook",
        // },
        {
          title: "LinkedIn",
          href: "https://www.linkedin.com/company/81293105/admin/feed/posts/",
          id: "linkedin",
        },
      ],
    },
  },
  {
    title: messages.forProfessionals,
    links: [
      {
        title: messages.listYourServices,
        href: "/professional-homepage",
        id: "for-professionals",
      },
      {
        title: messages.switchToOrchid,
        href: "/switch-ehr-orchid",
        id: "switch-to-orchid",
      },
      {
        title: messages.writeForUs,
        href: "/write-for-us",
        id: "write-for-us",
      },
      {
        title: messages.savingsCalculator,
        href: "/ehr-cost-savings",
        id: "savings-calculator",
      },
      {
        title: messages.aiProgressNoteGenerator,
        href: "/ai-progress-note-generator",
        id: "ai-progress-note-generator",
      },
      {
        title: messages.measurementBasedCare,
        href: "/measurement-based-care",
        id: "measurement-based-care",
      },
      {
        title: messages.partnerWithOrchid,
        href: "/partner-with-orchid",
        id: "partner-with-orchid",
      },
      {
        title: messages.security,
        href: "/ehr-security",
        id: "ehr-security",
      },
      {
        title: messages.faq,
        href: "/orchid-ehr-faq",
        id: "faq",
      },
    ],
  },
  {
    title: "Top Specialties",
    links: SPECIALTIES.map((specialty) => ({
      title: specialty,
      href: `/${specialty.toLowerCase()}s`,
      id: specialty.toLowerCase(),
    })),
  },
  ...SPECIALTIES.map((specialty) => ({
    title: `Find ${specialty}`,
    links: STATE_SEARCH_OPTIONS.map((state) => ({
      title: `${state.label} ${specialty}s`,
      href: `/${specialty.toLowerCase()}s/${stateNameToUrlSegment(
        state.label,
      )}`,
      id: `${state.label}-${specialty.toLowerCase()}s`,
    })),
    collapseAfter: 16,
    expandLabel: `View all ${specialty}s`,
  })),
];

export const FOOTER_SECTIONS_BOTTOM: {
  title: string;
  href?: string;
  id?: string;
}[] = [
  { title: "© 2025 Orchid Exchange, Inc." },
  { title: messages.privacy, href: "/privacy-policy", id: "privacy-policy" },
  { title: messages.termsOfUse, href: "/terms-of-use", id: "terms-of-use" },
  {
    title: messages.acceptableUsePolicy,
    href: "/AUP",
    id: "acceptable-use-policy",
  },
];

import React, { Suspense, useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
// side effects
import "lazysizes";
/**
 * lazysizes attribute change / re-initialization extension;
 * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/attrchange#lazysizes-attribute-change--re-initialization-extension
 */
import "lazysizes/plugins/attrchange/ls.attrchange";
import { Helmet } from "react-helmet";
// hooks
import { useUser } from "contexts/UserContext";
import { useAuthModals } from "contexts/auth-modals-context";
// styles
import "./App.scss";
// utils
import { reportError, reportProblem } from "utils/frontend_error_reporting";
import shouldSuppressError from "utils/shouldSuppressError";
import * as amplitude from "@amplitude/analytics-browser";
// constants
import { GENERAL_ROUTES } from "constants/generalRoutes";
import { analytics } from "constants/segment";
// components
import AppNavigation from "components/app-navigation/AppNavigation";
import Layout from "components/layout";
import Loading from "components/Loading";
import Intercom from "intercom";
import { IS_DEV_ENV } from "constants/isDevEnv";
import { AMPLITUDE_PUBLIC_KEY } from "constants/amplitudePublicKey";
import WriteForUs from "components/write-for-us/WriteForUs";
import MeasurementBasedCare from "components/measurement-based-care/MeasurementBasedCare";
import PartnerWithOrchid from "components/partner-with-orchid/PartnerWithOrchid";
import SavingsCalculator from "components/savings-calculator/SavingsCalculator";
import SwitchToOrhid from "components/switch-to-orchid/SwitchToOrchid";
import OrchidAI from "components/orchid-ai/OrchidAI";
import FAQPage from "components/faq-page/FAQPage";
import EHRSecurity from "components/ehr-security/EHRSecurity";
import getCanonicalLink from "utils/getCanonicalLink";
import {
  ALL_POSTS_BLOG_CATEGORY,
  BLOG_CATEGORIES,
} from "components/Blogging/constants/blog-categories";
import {
  ALL_PRESS_CATEGORY,
  PRESS_CATEGORIES,
} from "components/press/constants/press-categories";
import FullWidthImage from "components/full-width-image";
import lazy from "utils/lazyOrReload";
import GetStartedDropdown from "components/auth/GetStartedDropdown";
import QuickIntake from "components/quick-intake/QuickIntake";
import AppointmentCheckout from "components/AppointmentBook/AppointmentCheckout";

const PostBlog = lazy(
  () => import("./components/Blogging/components/post-blog/PostBlog"),
);
const MemberHome = lazy(() => import("components/Homepage/member-home"));

const OrchidProHomeWeb = lazy(
  () => import("./components/Homepage/orchid-pro-home-web"),
);
const VideoChat = lazy(() => import("./components/video-chat/VideoChat"));
const VideoChatV2 = lazy(
  () => import("./components/video-chat-v2/VideoChatV2"),
);
const AboutUs = lazy(() => import("./components/about-us"));

const AllGroupSessionList = lazy(
  () => import("./components/GroupSession/AllGroupSessionList"),
);
const GroupSessionSeriesPage = lazy(
  () => import("./components/GroupSession/GroupSessionSeriesPage"),
);
const GroupSeriesCheckout = lazy(
  () => import("./components/GroupSession/GroupSeriesCheckout"),
);
const GroupSessionCheckoutConfirm = lazy(
  () => import("./components/GroupSession/checkout-confirm/CheckoutConfirm"),
);

const Welcome = lazy(() => import("./components/auth/Welcome"));
const WelcomeToOrchid = lazy(() => import("components/welcome-to-orchid"));

const OrchidProRegister = lazy(
  () => import("./components/auth/orchid-pro-register/OrchidProRegister"),
);
const MemberRegister = lazy(
  () => import("./components/auth/member-register/MemberRegister"),
);
const UserLoginForm = lazy(
  () => import("./components/auth/user-login-form/UserLoginForm"),
);
const ResetPasswordPage = lazy(
  () => import("./components/auth/ResetPasswordPage"),
);

const MarketPlace = lazy(() => import("./components/MarketPlace/MarketPlace"));
const InfoPage = lazy(
  () => import("./components/MarketPlace/info-page/InfoPage"),
);
const PracticeGroup = lazy(
  () => import("components/MarketPlace/practice-group"),
);
const PaymentConfirmation = lazy(
  () => import("./components/AppointmentBook/payment-confirm/PaymentConfirm"),
);

const BlogList = lazy(
  () => import("./components/Blogging/components/blog-list/BlogList"),
);
const Blog = lazy(() => import("./components/Blogging/Blog"));
const PostBlogConfirmation = lazy(
  () =>
    import(
      "./components/Blogging/components/post-blog-confirmation/PostBlogConfirmation"
    ),
);
const ProfileUrlInputPage = lazy(
  () => import("./components/provider-intake/profile-url"),
);

const ThanksForSharing = lazy(
  () => import("./components/DataScience/thanks-for-sharing"),
);

const AUP = lazy(() => import("./components/Terms/AUP"));
const TermsOfUse = lazy(() => import("./components/Terms/TermsOfUse"));
const WaiverOfLiability = lazy(
  () => import("./components/Terms/WaiverOfLiability"),
);
const PrivacyPolicy = lazy(() => import("./components/Terms/Privacy"));
const AIPrivacyPolicy = lazy(
  () => import("./components/Terms/AIPrivacyPolicy"),
);
const IndependentContractorAgreement = lazy(
  () => import("./components/Terms/IndependentContractor"),
);

const DoctorDashboard = lazy(
  () => import("./components/Dashboard/DoctorDashboard/DoctorDashboard"),
);
const MemberDashboard = lazy(
  () => import("./components/Dashboard/MemberDashboard/MemberDashboard"),
);

const ZoomIntegrationDocumentation = lazy(
  () =>
    import(
      "./components/zoom-integration-documentation/ZoomIntegrationDocumentation"
    ),
);

const Support = lazy(() => import("./components/support/Support"));

const HowDoYouFeel = lazy(
  () => import("./components/DataScience/how-do-you-feel"),
);
const YourDailyMoods = lazy(
  () => import("./components/DataScience/your-daily-moods"),
);

const GoogleAuthResult = lazy(
  () => import("components/Dashboard/DoctorDashboard/views/google-auth-result"),
);

const PageNotFound = lazy(() => import("./components/page-not-found"));
const UpdatePreference = lazy(
  () => import("./components/email-preference/update-preference"),
);
const UnsubscribeFromAll = lazy(
  () => import("./components/email-preference/unsubscribe-from-all"),
);

const EmailPreference = lazy(() => import("./components/email-preference"));
const Resubscribe = lazy(
  () => import("./components/email-preference/resubscribe"),
);
const PricingPage = lazy(
  () => import("./components/Subscription/pricing-page/Pricing"),
);

const SubscriptionCheckout = lazy(
  () => import("./components/Subscription/subscription-checkout"),
);

const CheckoutAbandoned = lazy(
  () =>
    import(
      "./components/Subscription/subscription-checkout/checkout-abandoned"
    ),
);

const ClientPortal = lazy(
  () => import("./components/client-portal/ClientPortal"),
);
const Pressroom = lazy(() => import("./components/press/Pressroom"));

const Press = lazy(() => import("./components/press/Press"));

window.onerror = function (msg, url, lineNo, columnNo, error) {
  if (IS_DEV_ENV) {
    return;
  }
  shouldSuppressError(msg);
  if (error) {
    reportError({
      reporter_module: "global_error_handler",
      context: { msg, url, lineNo, columnNo },
      err: error,
    });
  } else {
    reportProblem({
      reporter_module: "global_error_handler",
      context: { msg, url, lineNo, columnNo },
      frontend_error: msg.toString(),
    });
  }
  return false;
};

const LOGIN_BACKGROUND = "/img/video/login-background.png";

export default function App() {
  const { pathname } = useLocation();
  const { user, updateUserInfo } = useUser();
  const authModalsProps = useAuthModals();
  /**
   * disable navbar scrolling
   */
  const [disableNavbarScrolling, setDisableNavbarScrolling] = useState(false);

  const pageHeightCallback = (dataFromChild) => {
    if (dataFromChild < 1000) {
      setDisableNavbarScrolling(true);
    }
  };

  const proRoutes =
    user?.user_type !== "orchid_pro" ? null : (
      <Route path="/pro-dashboard" component={DoctorDashboard} />
    );

  const memberRoutes =
    user?.user_type !== "member" ? null : (
      <Route path="/member-dashboard" component={MemberDashboard} />
    );

  useEffect(() => {
    if (pathname.endsWith("/member-intake-form")) {
      authModalsProps.setCustomMemberIntakeFormWrapperProps({
        isMemberIntakeFormIncomplete: false,
      });
      authModalsProps.openMemberIntakeFormWrapper();
    }
  }, [pathname, authModalsProps]);

  useEffect(() => {
    if (
      user === null &&
      (pathname.startsWith("/pro-dashboard") ||
        pathname.startsWith("/member-dashboard"))
    ) {
      return authModalsProps.openAuthModals();
    }
  }, [user, pathname, authModalsProps]);

  analytics.page(useLocation());

  if (!IS_DEV_ENV) {
    amplitude.init(AMPLITUDE_PUBLIC_KEY);
  }

  return (
    <>
      <Helmet>
        <title>Orchid | Online Professional Therapy & Support Groups</title>
        <meta
          name="description"
          content="This is private practice — made perfect. Meet Orchid, the most efficient EHR for mental health professionals &amp; practitioners that takes the guesswork out of growing."
        />

        <link rel="canonical" href={getCanonicalLink()} />

        <meta property="og:site_name" content="Orchid" />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:title"
          content="Orchid | Online Professional Therapy & Support Groups"
        />
        <meta
          property="og:description"
          content="This is private practice — made perfect. Meet Orchid, the most efficient EHR for mental health professionals &amp; practitioners that takes the guesswork out of growing."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={getCanonicalLink()} />
        <meta
          property="og:image"
          content="https://orchid.exchange/orchid-logo-meta-tag.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://orchid.exchange/orchid-logo-meta-tag.png"
        />
        <meta property="og:image:alt" content="Orchid Logo" />
        <meta property="og:image:width" content="2501" />
        <meta property="og:image:height" content="1397" />
        <meta property="og:image:type" content="image/png" />

        <meta
          name="twitter:title"
          content="Orchid | The Most Efficient EHR for Mental Health"
        />
        <meta
          name="twitter:description"
          content="This is private practice — made perfect. Meet Orchid, the most efficient EHR for mental health professionals &amp; practitioners that takes the guesswork out of growing."
        />
        <meta
          name="twitter:image"
          content="https://orchid.exchange/orchid-logo-meta-tag.png"
        />
        <meta
          name="twitter:image:src"
          content="https://orchid.exchange/orchid-logo-meta-tag.png"
        />
        <meta name="twitter:image:alt" content="Orchid Logo" />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      {window.location.hostname.endsWith(".secureportal.health") ||
      window.location.hostname === "127.0.0.1" ? (
        <Layout>
          <Suspense fallback={<Loading />}>
            <ClientPortal />
          </Suspense>
        </Layout>
      ) : pathname.startsWith("/pro-dashboard/session-review") &&
        !user?.signup_form_finished ? (
        <Layout>
          <FullWidthImage
            src={LOGIN_BACKGROUND}
            alt={"Post session summary page background"}
          />
        </Layout>
      ) : (
        <Layout>
          <Intercom />
          <AppNavigation disableNavbarScrolling={disableNavbarScrolling} />
          <Suspense fallback={<Loading />}>
            <Switch>
              {proRoutes}
              {memberRoutes}
              <Route exact path="/" component={MemberHome} />
              <Route
                exact
                path="/professional-homepage"
                component={OrchidProHomeWeb}
              />
              <Route
                exact
                path="/pricing/checkout-cancel"
                component={CheckoutAbandoned}
              />
              <Route exact path="/pricing" component={PricingPage} />
              <Route
                exact
                path="/pricing/:sub_class/:sub_period/:type"
                component={SubscriptionCheckout}
              />
              <Route exact path="/get-started" component={GetStartedDropdown} />
              <Route exact path="/login" component={UserLoginForm} />
              <Route
                exact
                path="/reset-password"
                component={ResetPasswordPage}
              />
              <Route exact path="/member-intake-form" render={() => <div />} />
              <Route
                exact
                path="/professional-register"
                component={OrchidProRegister}
              />
              <Route exact path="/member-register" component={MemberRegister} />
              <Route
                exact
                path="/group-session"
                render={(props) => (
                  <AllGroupSessionList user={user} {...props} />
                )}
              />
              <Route
                exact
                path="/group-session/series/:group_sessions_series_id"
                render={(props) => (
                  <GroupSessionSeriesPage user={user} {...props} />
                )}
              />
              <Route
                exact
                path="/group-session/series/:group_sessions_series_id/checkout"
                render={(props) => (
                  <GroupSeriesCheckout
                    {...props}
                    user={user}
                    updateUserInfo={updateUserInfo}
                    {...authModalsProps}
                  />
                )}
              />
              <Route
                exact
                path="/group-session/series/:group_sessions_series_id/checkout-confirm"
                component={GroupSessionCheckoutConfirm}
              />
              <Route exact path="/AUP" render={() => <AUP />} />
              <Route exact path="/terms-of-use" render={() => <TermsOfUse />} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                exact
                path="/clinician-ai-privacy-policy"
                component={AIPrivacyPolicy}
              />
              <Route
                exact
                path="/waiver-of-liability"
                render={() => <WaiverOfLiability />}
              />
              <Route
                exact
                path="/independent-contractor-agreement"
                render={() => <IndependentContractorAgreement />}
              />
              <Route exact path="/confirm-email" component={WelcomeToOrchid} />
              <Route exact path="/welcome" component={Welcome} />
              <Route exact path="/quick-intake" component={QuickIntake} />
              <Route
                exact
                path="/profile-input"
                component={ProfileUrlInputPage}
              />

              <Route
                exact
                path="/payment-confirmation"
                component={PaymentConfirmation}
              />
              <Route exact path="/video-chat" component={VideoChat} />
              <Route exact path="/video-chat-2" component={VideoChatV2} />
              <Route
                exact
                path={GENERAL_ROUTES.press}
                render={() => (
                  <Pressroom initialCategory={ALL_PRESS_CATEGORY} />
                )}
              />
              {PRESS_CATEGORIES.filter((category) => category.value !== "").map(
                (category) => (
                  <Route
                    key={category.value}
                    exact
                    path={`/press/${category.value}`}
                    render={() => <Pressroom initialCategory={category} />}
                  />
                ),
              )}
              <Route exact path="/press/:press_url" component={Press} />
              <Route
                exact
                path={GENERAL_ROUTES.blogs}
                render={() => (
                  <BlogList initialCategory={ALL_POSTS_BLOG_CATEGORY} />
                )}
              />
              {BLOG_CATEGORIES.filter((category) => category.value !== "").map(
                (category) => (
                  <Route
                    key={category.value}
                    exact
                    path={`/blogs/${category.value}`}
                    render={() => <BlogList initialCategory={category} />}
                  />
                ),
              )}
              <Route
                path="/blogs/:url"
                render={(props) => <Blog {...props} />}
              />
              <Route exact path="/post-blog" component={PostBlog} />
              <Route
                exact
                path="/post-blog-confirmation"
                render={(props) => <PostBlogConfirmation {...props} />}
              />
              <Route
                exact
                path="/professional-search"
                render={() => <MarketPlace />}
              />
              <Route
                exact
                path="/zoom-integration-documentation"
                render={() => <ZoomIntegrationDocumentation />}
              />
              <Route exact path="/support" render={() => <Support />} />
              <Route exact path="/book" render={() => <MarketPlace />} />
              <Route
                exact
                path={`/professionals/:initialStateUrl`}
                render={() => <MarketPlace />}
              />
              <Route
                exact
                path={`/psychologists/:initialStateUrl?`}
                render={() => <MarketPlace initialSpecialty="Psychologist" />}
              />
              <Route
                exact
                path={`/psychiatrists/:initialStateUrl?`}
                render={() => <MarketPlace initialSpecialty="Psychiatry" />}
              />
              <Route
                exact
                path={`/therapists/:initialStateUrl?`}
                render={() => <MarketPlace initialSpecialty="Therapist" />}
              />
              {/* <Route
              exact
              path="/professional-search/page/:page"
              render={(props) => (
                <MarketPlace
                  {...props}
                  updateUserInfo={updateUserInfo}
                  user={user}
                />
              )}
            /> */}
              <Route
                exact
                path="/thanksforsharing"
                render={() => <ThanksForSharing />}
              />
              <Route
                exact
                path="/professional-search/:id_or_custom_url"
                render={() => (
                  <InfoPage pageHeightCallback={pageHeightCallback} />
                )}
              />
              <Route
                exact
                path="/professional-search/:id_or_custom_url/checkout"
                component={AppointmentCheckout}
              />
              <Route
                exact
                path="/professional-search/:id_or_custom_url/checkout-confirm/:timeslot_id"
                component={PaymentConfirmation}
              />
              <Route exact path="/about-us" component={AboutUs} />
              <Route
                exact
                path={"/measurement-based-care"}
                component={MeasurementBasedCare}
              />
              <Route
                exact
                path={"/partner-with-orchid"}
                component={PartnerWithOrchid}
              />
              <Route exact path={"/write-for-us"} component={WriteForUs} />
              <Route
                exact
                path={"/switch-ehr-orchid"}
                component={SwitchToOrhid}
              />
              <Route
                exact
                path={"/ehr-cost-savings"}
                component={SavingsCalculator}
              />
              <Route
                exact
                path={"/ai-progress-note-generator"}
                component={OrchidAI}
              />
              <Route exact path={"/orchid-ehr-faq"} component={FAQPage} />
              <Route exact path={"/ehr-security"} component={EHRSecurity} />
              <Route
                exact
                path="/feelings"
                render={() => <HowDoYouFeel updateUserInfo={updateUserInfo} />}
              />
              <Route
                exact
                path="/daily-moods"
                render={() => (
                  <YourDailyMoods updateUserInfo={updateUserInfo} />
                )}
              />
              {/* Google Calendar feature */}
              {/* google auth redirect */}
              <Route
                exact
                path={GENERAL_ROUTES.googleCalendarSuccess}
                component={GoogleAuthResult}
              />
              <Route
                exact
                path={GENERAL_ROUTES.googleCalendarFailure}
                component={GoogleAuthResult}
              />
              <Route exact path="/page-not-found" component={PageNotFound} />
              <Route
                exact
                path="/email-preference/update-preference"
                component={UpdatePreference}
              />
              <Route
                exact
                path="/email-preference/unsubscribe"
                component={UnsubscribeFromAll}
              />
              <Route
                exact
                path="/email-preference"
                render={() => <EmailPreference />}
              />
              <Route
                exact
                path="/email-preference/resubscribe"
                component={Resubscribe}
              />
              <Route
                exact
                path="/:practice_group_name"
                component={PracticeGroup}
              />
            </Switch>
          </Suspense>
        </Layout>
      )}
    </>
  );
}

import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Orchid Pro creates Invite for A Series
 * @see https://api.orchidsquirrel.com/api/scheduler/docs#/appointment_series/orchid_pro_creates_invite_for_a_series_api_scheduler_v1_appointment_series__post
 */
export default async function postAppointmentSeries(
  arg: InviteSeriesInput,
  primaryOrchidProUserId?: string,
): Promise<{
  appointment_series: AppointmentSeriesMetaData;
  invites_created: AppointmentWithSeriesInfo[];
}> {
  const res = await fetchOrchidAPI(
    `/api/scheduler/v1/appointment/series/${
      primaryOrchidProUserId
        ? `?primary_orchid_pro_user_id=${primaryOrchidProUserId}`
        : ""
    }`,
    {
      headers: { "content-type": "application/json" },
      body: JSON.stringify(arg),
      method: "POST",
    },
  );
  if (!res.ok) {
    throw new Error(
      `failed to post appointment series: ${getErrorDetail(await res.text())}`,
    );
  }
  return res.json();
}

import { getErrorDetail } from "components/utility/utils";
import { usePrivateAPIData } from "contexts/api-data-provider";
import type PubNub from "pubnub";
import { useEffect, useMemo, useState } from "react";

type PubNubKeys = {
  publish_key: string;
  subscribe_key: string;
  uuid: string;
  token: string;
};

const usePubNubInstance = () => {
  const [pubNubInstance, setPubNubInstance] = useState<PubNub | null>(null);
  const {
    pubNubKeys: { result: pubNubKeys, errorText: pubNubKeysError },
  } = usePrivateAPIData(
    {
      pubNubKeys: { resource: "/api/pubnub_grant/v2/pubnub_keys" },
    },
    0,
  ) as { [key: string]: { result: PubNubKeys; errorText?: string } };

  const pubNubKeysErrorDetail = useMemo(
    () => getErrorDetail(pubNubKeysError),
    [pubNubKeysError],
  );

  useEffect(() => {
    if (!pubNubKeys) {
      setPubNubInstance(null);
      return;
    }
    (async () => {
      // load module
      const { default: PubNub } = await import("pubnub");

      if (!pubNubKeys) return;

      const pubnub = new PubNub({
        ssl: true,
        publishKey: pubNubKeys.publish_key,
        subscribeKey: pubNubKeys.subscribe_key,
        userId: pubNubKeys.uuid,
        // logVerbosity: true,
      });

      setPubNubInstance(pubnub);
    })();
  }, [pubNubKeys]);

  return {
    pubNubInstance,
    pubNubError: pubNubKeysErrorDetail,
  };
};

export default usePubNubInstance;

import { useMemo } from "react";
import {
  Navbar,
  Nav,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { ReactComponent as Menu } from "assets/svg/menu-hamburger.svg";
import { ReactComponent as OutlineMessageCircleLines } from "assets/svg/outline-message-circle-lines.svg";
import { ReactComponent as OutlineDashboard } from "assets/svg/outline-dashboard.svg";
// import { ReactComponent as OutlineBell } from "assets/svg/outline-bell.svg";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import { useUser } from "contexts/UserContext";
import DropdownToggle from "components/dropdown-toggle/DropdownToggle";
import User from "./components/user";
import MessageNotifications from "components/message-notifications";
import NavBrand from "components/nav-brand/NavBrand";
import { GENERAL_ROUTES } from "constants/generalRoutes";
import { AmplitudePageName, track } from "utils/amplitude";
import logoutUser from "./utils/logoutUser";
import getClasses from "utils/getClasses";
import styles from "./AfterLoginNavbar.module.scss";
import { useSubscription } from "contexts/subscription-context";

const AMPLITUDE_PAGE_NAME: AmplitudePageName = "common-navigation";

const AfterLoginNavbar = () => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const { canAccessFeature, canAccessPlatform } = useSubscription();
  const { user } = useUser();

  const minor = useMemo(() => {
    return user?.minors?.find(
      ({ minor }) => minor.orchid_user_id === user?.current_minor_user_id,
    )?.minor;
  }, [user]);

  return (
    <Navbar
      className={getClasses(styles["nav-bar"], "shadow-sm background-white")}
      expand="lg"
    >
      <NavBrand />
      <Navbar.Toggle aria-controls="dashboard-navbar-nav" />
      <Navbar.Collapse id="dashboard-navbar-nav">
        <Nav className={!isPhonePortrait ? styles["nav-bar__left"] : ""}>
          <NavLink
            exact
            className="nav-link"
            to={GENERAL_ROUTES.professionalSearch}
            onClick={() => {
              if (user?.user_type === "orchid_pro") {
                track(AMPLITUDE_PAGE_NAME, "find-a-professional-link", "click");
              }
            }}
          >
            Find a professional
          </NavLink>
          <NavLink
            exact
            className="nav-link"
            to={GENERAL_ROUTES.groupSession}
            onClick={() => {
              if (user?.user_type === "orchid_pro") {
                track(
                  AMPLITUDE_PAGE_NAME,
                  "find-a-support-group-link",
                  "click",
                );
              }
            }}
          >
            Find a support group
          </NavLink>

          <NavLink
            exact
            className="nav-link"
            to={GENERAL_ROUTES.pricing}
            onClick={() => {
              track(AMPLITUDE_PAGE_NAME, "pricing-link", "click");
            }}
          >
            Pricing
          </NavLink>

          <NavLink
            exact
            className="nav-link"
            to={GENERAL_ROUTES.blogs}
            onClick={() => {
              if (user?.user_type === "orchid_pro") {
                track(AMPLITUDE_PAGE_NAME, "blog-link", "click");
              }
            }}
          >
            Blog
          </NavLink>

          {/* <NavLink
            exact
            className="nav-link"
            to={GENERAL_ROUTES.press}
            onClick={() => {
              if (user?.user_type === "orchid_pro") {
                track(AMPLITUDE_PAGE_NAME, "press-link", "click");
              }
            }}
          >
            Press
          </NavLink> */}
        </Nav>
        {user?.user_type === "orchid_pro" ? (
          <Nav
            className={getClasses(
              "ml-auto",
              !isPhonePortrait && "align-items-center",
            )}
          >
            {canAccessPlatform(user) && (
              <>
                <NavLink
                  exact
                  className="nav-link"
                  to="/pro-dashboard/homepage"
                  aria-label={"homepage"}
                  onClick={() => {
                    track(AMPLITUDE_PAGE_NAME, "dashboard-link", "click");
                  }}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip">Dashboard</Tooltip>}
                  >
                    <OutlineDashboard aria-hidden="true" />
                  </OverlayTrigger>
                </NavLink>

                {canAccessFeature("messaging") && (
                  <NavLink
                    exact
                    className="nav-link"
                    to="/pro-dashboard/messages"
                    aria-label={"messages"}
                    onClick={() => {
                      track(AMPLITUDE_PAGE_NAME, "messages-link", "click");
                    }}
                  >
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="button-tooltip">Messages</Tooltip>}
                    >
                      <div aria-hidden="true">
                        <OutlineMessageCircleLines /> <MessageNotifications />
                      </div>
                    </OverlayTrigger>
                  </NavLink>
                )}
              </>
            )}
            {/* <NavLink
                exact
                className="nav-link"
                to="/pro-dashboard/notifications"
              >
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip">Notifications</Tooltip>}
                >
                  <OutlineBell />
                </OverlayTrigger>
              </NavLink> */}
            <DropdownToggle
              title={<User />}
              className={isPhonePortrait ? "ml-0" : ""}
              Caret={<Menu />}
            >
              {canAccessPlatform(user) && (
                <>
                  <Dropdown.Item
                    href="https://help.orchid.exchange/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      track(AMPLITUDE_PAGE_NAME, "support-link", "click");
                    }}
                  >
                    Support
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="/pro-dashboard/policies"
                    onClick={() => {
                      track(AMPLITUDE_PAGE_NAME, "policies-link", "click");
                    }}
                  >
                    Policies
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Item
                onClick={() => {
                  track(AMPLITUDE_PAGE_NAME, "blog-link", "click");
                  logoutUser(user);
                }}
              >
                Log out
              </Dropdown.Item>
            </DropdownToggle>
          </Nav>
        ) : (
          <Nav className="ml-auto align-items-center">
            {minor && (
              <div className={styles["nav-bar__view-minor"]}>
                {`You are viewing as a contact of ${minor.first_name || ""} ${
                  minor.last_name || ""
                }`}
              </div>
            )}
            <NavLink
              style={{ whiteSpace: "nowrap" }}
              exact
              className="nav-link"
              to="/member-dashboard/messages"
              aria-label={"messages"}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Messages</Tooltip>}
              >
                <div aria-hidden="true">
                  <OutlineMessageCircleLines /> <MessageNotifications />
                </div>
              </OverlayTrigger>
            </NavLink>
            <NavLink
              exact
              className="nav-link"
              to="/member-dashboard/homepage"
              aria-label={"homepage"}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Dashboard</Tooltip>}
              >
                <OutlineDashboard aria-hidden="true" />
              </OverlayTrigger>
            </NavLink>
            <DropdownToggle title={<User />} Caret={<Menu />}>
              <Dropdown.Item as={NavLink} to="/member-dashboard/sessions">
                My sessions
              </Dropdown.Item>
              <Dropdown.Item as={NavLink} to="/member-dashboard/assignments">
                Assignment
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={NavLink} to="/member-dashboard/edit-profile">
                Profile
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                href="https://help.orchid.exchange/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Help
              </Dropdown.Item>
              <Dropdown.Item
                as={NavLink}
                to={"/member-dashboard/switch-accounts"}
              >
                Switch profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => logoutUser(user)}>
                Log out
              </Dropdown.Item>
            </DropdownToggle>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AfterLoginNavbar;

import React, { ReactNode, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Loading from "components/Loading";

import { PhonePortraitContextProvider } from "./phone-portrait-context";
import { SubscriptionContextProvider } from "./subscription-context";
import { UserContext, UserContextProvider } from "./UserContext";
import { AuthModalsContextProvider } from "./auth-modals-context";
import { AppointmentsProvider } from "./appointments/appointments-provider";
import { TimeOnPageContextProvider } from "./time-on-page";
import lazy from "utils/lazyOrReload";
import { IS_DEV_ENV } from "constants/isDevEnv";

const OrchidWebSocketProvider = lazy(() =>
  import("./WebSocketContext").then((res) => ({
    default: res.OrchidWebSocketProvider,
  })),
);
const GroupSessionsProvider = lazy(() =>
  import("./GroupSessionsProviderContext").then((res) => ({
    default: res.GroupSessionsProvider,
  })),
);
const NotificationsProvider = lazy(() =>
  import("./NotificationsProviderContext").then((res) => ({
    default: res.NotificationsProvider,
  })),
);
const OrchidPubNubStateProvider = lazy(() =>
  import("./pubnub/OrchidPubNubStateContext").then((res) => ({
    default: res.OrchidPubNubStateProvider,
  })),
);

const OrchidDrive = lazy(() =>
  import("./orchid-drive").then((res) => ({
    default: res.OrchidDriveInternal,
  })),
);

type Props = { children: NonNullable<ReactNode> };

const queryClient = new QueryClient();

if (IS_DEV_ENV) {
  //@ts-expect-error
  window.queryClient = queryClient;
}

const AppContextProvider = ({ children }: Props) => (
  <QueryClientProvider client={queryClient}>
    <PhonePortraitContextProvider>
      <UserContextProvider>
        <AuthModalsContextProvider>
          <SubscriptionContextProvider>
            <TimeOnPageContextProvider>
              <UserContext.Consumer>
                {({ user }) =>
                  !user ? (
                    children
                  ) : (
                    <Suspense fallback={<Loading />}>
                      <OrchidWebSocketProvider>
                        <GroupSessionsProvider>
                          <AppointmentsProvider>
                            <NotificationsProvider user={user}>
                              <OrchidPubNubStateProvider>
                                <OrchidDrive>{children}</OrchidDrive>
                              </OrchidPubNubStateProvider>
                            </NotificationsProvider>
                          </AppointmentsProvider>
                        </GroupSessionsProvider>
                      </OrchidWebSocketProvider>
                    </Suspense>
                  )
                }
              </UserContext.Consumer>
            </TimeOnPageContextProvider>
          </SubscriptionContextProvider>
        </AuthModalsContextProvider>
      </UserContextProvider>
    </PhonePortraitContextProvider>
  </QueryClientProvider>
);

export default AppContextProvider;

import DOMPurify from "dompurify";
import { useCallback } from "react";
import { fetchOrchidAPI } from "utils/api";
import { genUniqueId } from "utils/fn";
import findChatAccessDataByCognitoSub from "../utils/find-chat-access-data-by-cognito-sub";
import getLastConfirmedMessage from "../utils/get-last-confirmed-message";
import reportRead from "../utils/report-read";

import type Pubnub from "pubnub";
import { reportError } from "utils/frontend_error_reporting";
import {
  ChatAccessDataMapByChannel,
  ChatOperations,
  PrivateChatSession,
} from "../pubnub.types";
import { NotificationsReducerAction } from "../state/notifications-reducer";
import { PartnersRecurerAction } from "../state/partners-reducer";
import pubNubTimetokenToDate from "../utils/pubnub-timetoken/pubnub-timetoken-to-date";

interface UseChatOperationsArgs {
  pubNubInstance: Pubnub | null;
  userSub: string | undefined;
  privateChats: Map<string, PrivateChatSession>;
  chatAccessDataByChannel: ChatAccessDataMapByChannel;
  partnersDispatch: React.Dispatch<PartnersRecurerAction>;
  notificationsDispatch: React.Dispatch<NotificationsReducerAction>;
  setCurrentPartnerSub: (value: string | undefined) => void;
  loadOlderMessagesForPartner: ChatOperations["loadOlderMessagesForPartner"];
}

const useChatOperations = ({
  pubNubInstance,
  userSub,
  privateChats,
  chatAccessDataByChannel,
  partnersDispatch,
  notificationsDispatch,
  setCurrentPartnerSub,
  loadOlderMessagesForPartner,
}: UseChatOperationsArgs) => {
  const addPartner = useCallback(
    (partnerSub: string) => {
      partnersDispatch({ type: "add", partners: new Set([partnerSub]) });
    },
    [partnersDispatch],
  );

  const openPrivateChat = useCallback(
    (partnerSub: string) => {
      setCurrentPartnerSub(partnerSub);
      partnersDispatch({ type: "add", partners: new Set([partnerSub]) });

      //TODO: Make sure history is loaded for the newly opened chat
      // privateChatsNeedLatestHistoryDispatch({
      //   type: "add",
      //   partners: new Set([partnerSub]),
      // });

      // report chat was read, it's safe to ignore error this time
      reportRead(partnerSub).catch(() => {});

      const access = findChatAccessDataByCognitoSub(
        chatAccessDataByChannel,
        partnerSub,
      );
      if (access)
        notificationsDispatch({
          type: "mark_read",
          channels: new Set([access.channel]),
        });
    },
    [
      chatAccessDataByChannel,
      notificationsDispatch,
      partnersDispatch,
      setCurrentPartnerSub,
    ],
  );

  const sendMessage = useCallback(
    async (partnerSub: string, messageContent: string) => {
      console.log("Operation sendMessage", partnerSub, messageContent);
      if (!pubNubInstance || !userSub) throw new Error("Chat not available");
      const msgUniqueId = genUniqueId(16);

      const access = findChatAccessDataByCognitoSub(
        chatAccessDataByChannel,
        partnerSub,
      );
      if (!access || !access.cipherKey) {
        console.error("no cipher found, chatAccess: ", chatAccessDataByChannel);
        throw new Error(
          "Unable to send message to this partner, please try again later",
        );
      }

      const message = DOMPurify.sanitize(messageContent) as string;

      if (!pubNubInstance.cipherKey) {
        pubNubInstance.setCipherKey(access.cipherKey);
      }

      await pubNubInstance.publish({
        channel: access.channel,
        message: JSON.stringify({ content: message }),
        meta: {
          sender_cognito_sub: userSub,
          msg_unique_id: msgUniqueId,
        },
      });

      await fetchOrchidAPI("/api/users/v1/contacts/report_message", {
        method: "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ cognito_sub: partnerSub }),
      });
    },
    [pubNubInstance, userSub, chatAccessDataByChannel],
  );

  const sendFilePrivateChat = useCallback(
    async (partnerSub: string, file, messageContent?: string) => {
      console.log(
        "Operation sendFilePrivateChat",
        partnerSub,
        file,
        messageContent,
      );
      if (!pubNubInstance || !userSub) throw new Error("Chat not available");

      const access = findChatAccessDataByCognitoSub(
        chatAccessDataByChannel,
        partnerSub,
      );
      if (!access || !access.cipherKey)
        throw new Error(
          "Unable to send file to this partner, please try again later",
        );

      if (!pubNubInstance.cipherKey) {
        pubNubInstance.setCipherKey(access.cipherKey);
      }

      const message = messageContent
        ? (DOMPurify.sanitize(messageContent) as string)
        : undefined;

      const msgUniqueId = genUniqueId(16);

      await pubNubInstance.sendFile({
        channel: access.channel,
        file: file,
        cipherKey: access.cipherKey,
        message: messageContent
          ? JSON.stringify({ content: message })
          : undefined,
        meta: {
          sender_cognito_sub: userSub,
          msg_unique_id: msgUniqueId,
        },
      });
    },
    [pubNubInstance, userSub, chatAccessDataByChannel],
  );

  const downloadFilePrivateChat = useCallback(
    async (partnerSub: string, fileId: string, filename: string) => {
      if (!pubNubInstance) throw new Error("Chat not available");

      try {
        const access = findChatAccessDataByCognitoSub(
          chatAccessDataByChannel,
          partnerSub,
        );
        if (access === undefined)
          throw new Error(
            "Unable to download file at this time, please try again later",
          );

        if (!pubNubInstance.cipherKey)
          pubNubInstance.setCipherKey(access.cipherKey);

        const file = await pubNubInstance.downloadFile({
          channel: access.channel,
          id: fileId,
          name: filename,
        });
        return file;
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },
    [pubNubInstance, chatAccessDataByChannel],
  );

  const setMessageReaction = useCallback(
    async (partnerSub: string, messageTimetoken: string, emoji: string) => {
      if (!pubNubInstance) throw new Error("Chat not available");

      const chat = privateChats.get(partnerSub);
      // let's just ignore, not confirming read isn't too bad unless happens a lot
      if (!chat) return;

      const message = chat.messages.find(
        (m) => m.messageTimetoken === messageTimetoken,
      );
      if (!message) throw new Error("message not found");

      if (message.sender !== partnerSub)
        throw new Error("Cannot set reaction to own message");

      // TODO: IMPROVE don't do anything if it's already set
      if (message.reaction) {
        try {
          await pubNubInstance.removeMessageAction({
            channel: chat.channel,
            messageTimetoken,
            actionTimetoken: message.reaction.actionTimetoken,
          });
        } catch (err) {
          console.warn(
            "failed to delete existing reaction, trying to set new anyway: ",
            err,
          );
        }
      }

      await pubNubInstance.addMessageAction({
        channel: chat.channel,
        messageTimetoken,
        action: {
          type: "reaction",
          value: emoji,
        },
      });
    },
    [pubNubInstance, privateChats],
  );

  const removeMessageReaction = useCallback(
    async (partnerSub: string, messageTimetoken: string) => {
      if (!pubNubInstance) throw new Error("Chat not available");

      const chat = privateChats.get(partnerSub);
      // let's just ignore, not confirming read isn't too bad unless happens a lot
      if (!chat) return;

      const message = chat.messages.find(
        (m) => m.messageTimetoken === messageTimetoken,
      );
      if (!message) throw new Error("message not found");

      if (message.sender !== partnerSub)
        throw new Error("Cannot set reaction to own message");

      // no reaction found, ignore silently
      if (!message.reaction) return;

      await pubNubInstance.removeMessageAction({
        channel: chat.channel,
        messageTimetoken,
        actionTimetoken: message.reaction.actionTimetoken,
      });
    },
    [pubNubInstance, privateChats],
  );

  const messageReceiptConfirm = useCallback(
    async (partnerSub: string) => {
      console.log("Operation messageReceiptConfirm", partnerSub);
      if (!pubNubInstance || !userSub) throw new Error("Chat not available");

      const chat = privateChats.get(partnerSub);
      // let's just ignore, not confirming read isn't too bad unless happens a lot
      if (!chat) return;

      const partnerMessages = chat.messages.filter(
        (msg) => msg.sender !== userSub,
      );

      const lastConfirmedMessage = getLastConfirmedMessage(partnerMessages);

      if (lastConfirmedMessage === null) {
        if (chat.hasNotification) {
          await reportRead(partnerSub);
        }
        return;
      }

      if (
        lastConfirmedMessage.messageTimetoken === null ||
        (chat.userMessageReadTimestamp !== null &&
          chat.userMessageReadTimestamp >=
            pubNubTimetokenToDate(lastConfirmedMessage.messageTimetoken))
      ) {
        return;
      }

      try {
        await pubNubInstance.addMessageAction({
          channel: chat.channel,
          messageTimetoken: lastConfirmedMessage.messageTimetoken,
          action: {
            type: "receipt",
            value: userSub,
          },
        });
      } catch (error) {
        // 409 CONFLICT - we already confirmed, ignore
        if (error instanceof Error && error["status"]?.statusCode !== 409) {
          await reportError({
            reporter_module: "OrchidPubNubStateProvider/messageReceiptConfirm",
            context: { status: error["status"] },
            err: error,
          });
        }
      }

      await reportRead(partnerSub);
    },
    [pubNubInstance, userSub, privateChats],
  );

  return {
    addPartner,
    openPrivateChat,
    sendMessage,
    sendFilePrivateChat,
    downloadFilePrivateChat,
    setMessageReaction,
    removeMessageReaction,
    messageReceiptConfirm,
    loadOlderMessagesForPartner,
  };
};

export default useChatOperations;

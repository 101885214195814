import queryStripePromise from "services/queryStripePromise";
import { getCardDeclineErrorMessage } from "utils/stripeUtils";

export default async function confirmWithStripe(clientSecret) {
  const stripe = await queryStripePromise();
  if (!stripe) return;

  let stripe_error;

  if (clientSecret) {
    if (clientSecret.startsWith("pi_")) {
      const { error } = await stripe.confirmCardPayment(clientSecret);
      if (error) stripe_error = error;
    } else {
      const { error } = await stripe.confirmCardSetup(clientSecret);
      if (error) stripe_error = error;
    }

    if (stripe_error) {
      console.error(stripe_error);
      const { message, decline_code } = stripe_error;
      const error_message = getCardDeclineErrorMessage(decline_code);
      if (error_message) {
        throw new Error(
          error_message
        );
      } else {
        throw new Error(`${message} Decline code: ${decline_code}`);
      }
    }
  } else {
    throw new Error(
      "Payment confirm failed: Missing credential to confirm payment",
    );
  }
  return;
}

import { ChangeEvent, KeyboardEvent, useRef } from "react";
import { ReactComponent as WarningCircle } from "assets/svg/warning-circle.svg";
import { Form } from "react-bootstrap";
import styles from "./OtpInput.module.scss";
import getClasses from "utils/getClasses";

const OTP_LENGTH = 6;

const OtpInput = ({
  value,
  onChange,
  error,
}: {
  value: string;
  onChange: (code: string) => void;
  error?: string;
}) => {
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value: inputValue } = e.target;

    const newValue = value.split("");
    newValue[index] = inputValue.slice(-1);
    inputsRef.current[index + 1]?.focus();

    onChange(newValue.join(""));
  };

  const handleKeyDown = (e: KeyboardEvent, index: number) => {
    switch (e.key) {
      case "Backspace":
        if (!value[index] && index > 0) {
          const newValue = value.split("");
          newValue[index - 1] = "";
          onChange(newValue.join(""));
          inputsRef.current[index - 1]?.focus();
        }
        break;
      case "ArrowLeft":
        inputsRef.current[index - 1]?.focus();
        break;
      case "ArrowRight":
        inputsRef.current[index + 1]?.focus();
        break;
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    // Place cursor at the end of the input
    const length = e.target.value.length;
    if (length) {
      setTimeout(() => {
        e.target.setSelectionRange(length, length);
      }, 0);
    }
  };

  const handlePaste = (e: React.ClipboardEvent, index: number) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData("text").trim();
    if (!pastedData) {
      return;
    }

    const newValue = value.split("");

    pastedData.split("").forEach((char, i) => {
      const indexToUpdate = index + i;
      if (indexToUpdate >= OTP_LENGTH) {
        return;
      }
      newValue[indexToUpdate] = char;
      inputsRef.current[indexToUpdate]?.focus();
    });

    onChange(newValue.join(""));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={getClasses(styles.otp, error && styles.hasError)}>
          {Array.from({ length: OTP_LENGTH }).map((_, index) => (
            <Form.Control
              style={{
                borderColor: !value[index] ? "#BDBDBD" : "#A3C5FF",
              }}
              key={index}
              type="text"
              value={value[index] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, index)
              }
              onKeyDown={(e) => handleKeyDown(e, index)}
              onFocus={handleFocus}
              onPaste={(e) => handlePaste(e, index)}
              ref={(input) => {
                inputsRef.current[index] = input;
              }}
              inputMode="numeric"
            />
          ))}
        </div>
        {error && (
          <div className={styles.error}>
            <WarningCircle />
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default OtpInput;

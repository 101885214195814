
const card_decline_codes = {
    "authentication_required": {
        "explanation": "The card was declined because the transaction requires additional authentication such as 3D Secure",
        "recommended_action": "Retry the payment using an authentication flow or contact the card issuer and contact Orchid’s Support Team if the issue persists"
    },
    "approve_with_id": {
        "explanation": "The payment cannot be authorized",
        "recommended_action": "Try the payment again or contact the card issuer and contact Orchid’s Support Team if the issue persists"
    },
    "call_issuer": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "card_not_supported": {
        "explanation": "The card does not support this type of purchase",
        "recommended_action": "Confirm with the card issuer whether this purchase is allowed and contact Orchid’s Support Team if the problem persists"
    },
    "card_velocity_exceeded": {
        "explanation": "The allowable limit on the card was exceeded",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "currency_not_supported": {
        "explanation": "The card does not support the specified currency",
        "recommended_action": "Verify if the card can be used with this currency and contact Orchid’s Support Team if the problem persists"
    },
    "do_not_honor": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "deprecated": {
        "explanation": "No longer used",
        "recommended_action": "No action required"
    },
    "do_not_try_again": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "duplicate_transaction": {
        "explanation": "A similar payment was submitted recently",
        "recommended_action": "Check if a recent payment already exists and contact Orchid’s Support Team if more assistance is needed"
    },
    "expired_card": {
        "explanation": "The card has expired",
        "recommended_action": "Use a valid card or payment method and contact Orchid’s Support Team if the issue persists"
    },
    "fraudulent": {
        "explanation": "The payment was declined because it appears fraudulent",
        "recommended_action": "Treat as a generic decline and contact Orchid’s Support Team if additional concerns arise"
    },
    "generic_decline": {
        "explanation": "The card was declined for an unknown reason or blocked by a fraud filter",
        "recommended_action": "Contact the card issuer for details and contact Orchid’s Support Team if the issue persists"
    },
    "incorrect_number": {
        "explanation": "The card number is incorrect",
        "recommended_action": "Enter the correct number or use a different payment method and contact Orchid’s Support Team if unresolved"
    },
    "incorrect_cvc": {
        "explanation": "The card’s security code is incorrect",
        "recommended_action": "Enter the correct CVC or use another card and contact Orchid’s Support Team if unresolved"
    },
    "incorrect_pin": {
        "explanation": "The PIN entered is incorrect; typically applies to card-present scenarios",
        "recommended_action": "Verify the correct PIN or use a different method and contact Orchid’s Support Team if confusion persists"
    },
    "incorrect_zip": {
        "explanation": "The postal code is incorrect",
        "recommended_action": "Enter the correct postal code and contact Orchid’s Support Team if unresolved"
    },
    "insufficient_funds": {
        "explanation": "The card has insufficient funds",
        "recommended_action": "Use an alternative payment method and contact Orchid’s Support Team if help is needed"
    },
    "invalid_account": {
        "explanation": "The card or associated account is invalid",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "invalid_amount": {
        "explanation": "The payment amount is invalid or exceeds allowed limits",
        "recommended_action": "Verify the amount with the card issuer and contact Orchid’s Support Team if unresolved"
    },
    "invalid_cvc": {
        "explanation": "The CVC code is incorrect",
        "recommended_action": "Enter the correct CVC or use another card and contact Orchid’s Support Team if unresolved"
    },
    "invalid_expiry_month": {
        "explanation": "The expiration month is invalid",
        "recommended_action": "Enter the correct expiration date and contact Orchid’s Support Team if the issue persists"
    },
     "invalid_expiry_year": {
        "explanation": "The expiration year is invalid",
        "recommended_action": "Enter the correct expiration date and contact Orchid’s Support Team if the issue persists"
    },
    "invalid_number": {
        "explanation": "The card number is incorrect",
        "recommended_action": "Use the correct card number or an alternative method and contact Orchid’s Support Team if unresolved"
    },
    "invalid_pin": {
        "explanation": "The PIN entered is incorrect; typically applies to card-present scenarios",
        "recommended_action": "Use the correct PIN or another method and contact Orchid’s Support Team if confusion remains"
    },
    "issuer_not_available": {
        "explanation": "The card issuer could not be reached",
        "recommended_action": "Attempt the payment again or contact the card issuer and contact Orchid’s Support Team if unresolved"
    },
    "lost_card": {
        "explanation": "The card was reported lost",
        "recommended_action": "Treat as a generic decline and contact Orchid’s Support Team if clarification is needed"
    },
    "merchant_blacklist": {
        "explanation": "The payment was declined due to a user-configured block list",
        "recommended_action": "Treat as a generic decline and contact Orchid’s Support Team if more information is needed"
    },
    "new_account_information_available": {
        "explanation": "The card or associated account is invalid",
        "recommended_action": "Contact the card issuer for details and contact Orchid’s Support Team if unresolved"
    },
    "no_action_taken": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "not_permitted": {
        "explanation": "The payment is not permitted",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if unresolved"
    },
    "offline_pin_required": {
        "explanation": "The card requires a PIN in a card-present scenario",
        "recommended_action": "Use a PIN-based transaction if applicable and contact Orchid’s Support Team if confusion remains"
    },
    "online_or_offline_pin_required": {
        "explanation": "The card requires a PIN; possibly online or offline in a card-present scenario",
        "recommended_action": "Prompt for a PIN if supported or insert the card and contact Orchid’s Support Team if needed"
    },
    "pickup_card": {
        "explanation": "The card may be lost or stolen",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if unresolved"
    },
    "pin_try_exceeded": {
        "explanation": "The maximum number of PIN attempts was exceeded",
        "recommended_action": "Use another payment method and contact Orchid’s Support Team if questions remain"
    },
    "processing_error": {
        "explanation": "An error occurred while processing the card",
        "recommended_action": "Try again later and contact Orchid’s Support Team if the issue persists"
    },
    "reenter_transaction": {
        "explanation": "The issuer could not process the payment for an unknown reason",
        "recommended_action": "Retry the payment and contact the card issuer if it remains declined and contact Orchid’s Support Team if unresolved"
    },
    "restricted_card": {
        "explanation": "The card cannot be used and may be lost or stolen",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if needed"
    },
    "revocation_of_all_authorizations": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "revocation_of_authorization": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "security_violation": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "service_not_allowed": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "stolen_card": {
        "explanation": "The card is reported stolen",
        "recommended_action": "Treat as a generic decline and contact Orchid’s Support Team if further guidance is needed"
    },
    "stop_payment_order": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "testmode_decline": {
        "explanation": "A Stripe test card number was used",
        "recommended_action": "A real card is required and contact Orchid’s Support Team if questions persist"
    },
    "transaction_not_allowed": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Contact the card issuer for more information and contact Orchid’s Support Team if the issue persists"
    },
    "try_again_later": {
        "explanation": "The card was declined for an unknown reason",
        "recommended_action": "Retry the payment and contact the card issuer if it continues to fail and contact Orchid’s Support Team if unresolved"
    },
    "withdrawal_count_limit_exceeded": {
        "explanation": "The transaction exceeds the card’s withdrawal or credit limit",
        "recommended_action": "Use another card or payment method and contact Orchid’s Support Team if further help is needed"
    }
}

const stripe_error_codes = {
    "account_closed": {
        "explanation": "The bank account used for this payment is closed",
        "recommended_action": "Use a different bank account or payment method. If the issue persists please contact Orchid’s Support Team for further assistance"
    },
    "account_country_invalid_address": {
        "explanation": "The country of the provided business address does not match the account’s country",
        "recommended_action": "Verify that the address matches the account’s country. If the issue persists please contact Orchid’s Support Team"
    },
    "account_error_country_change_requires_additional_steps": {
        "explanation": "A country change on the account requires extra steps",
        "recommended_action": "Please reach out to Orchid’s Support Team or Stripe support for further guidance"
    },
    "account_information_mismatch": {
        "explanation": "Some account information is conflicting or mismatched",
        "recommended_action": "Check that the business or holder name matches across all details. If unresolved please contact Orchid’s Support Team"
    },
    "account_invalid": {
        "explanation": "The account identifier provided is invalid",
        "recommended_action": "Confirm the account reference. If the issue persists please contact Orchid’s Support Team"
    },
    "account_number_invalid": {
        "explanation": "The bank account number appears invalid",
        "recommended_action": "Check the account number format. If the issue persists please contact Orchid’s Support Team"
    },
    "acss_debit_session_incomplete": {
        "explanation": "The ACSS debit session is incomplete",
        "recommended_action": "Try the request again after the session completes. If stuck please contact Orchid’s Support Team"
    },
    "alipay_upgrade_required": {
        "explanation": "Alipay payments are not supported through this legacy method",
        "recommended_action": "A newer Alipay integration is required. Please contact Orchid’s Support Team if guidance is needed"
    },
    "amount_too_large": {
        "explanation": "The transaction amount exceeds the allowed maximum",
        "recommended_action": "Use a smaller amount. If the issue persists please contact Orchid’s Support Team"
    },
    "amount_too_small": {
        "explanation": "The transaction amount is below the minimum required",
        "recommended_action": "Use a higher amount. If the issue persists please contact Orchid’s Support Team"
    },
    "api_key_expired": {
        "explanation": "The API key used for this request has expired",
        "recommended_action": "Obtain a new API key from the dashboard. If unable please contact Orchid’s Support Team"
    },
    "application_fees_not_allowed": {
        "explanation": "The proposed fee or money movement cannot be processed due to regulatory constraints",
        "recommended_action": "Contact Orchid’s Support Team about fee setup and regulatory requirements"
    },
    "authentication_required": {
        "explanation": "This payment requires additional authentication such as 3-D Secure",
        "recommended_action": "Perform the necessary authentication or use a different payment method. If unclear please contact Orchid’s Support Team"
    },
    "balance_insufficient": {
        "explanation": "Insufficient balance in the Stripe account to process this transaction",
        "recommended_action": "Try a smaller amount or add funds. If the issue persists please contact Orchid’s Support Team"
    },
    "balance_invalid_parameter": {
        "explanation": "An invalid parameter was used in a balance-related method",
        "recommended_action": "Review the parameters. If unresolved please contact Orchid’s Support Team"
    },
    "bank_account_bad_routing_numbers": {
        "explanation": "The bank account routing details do not support the given currency",
        "recommended_action": "Check the currency and routing info. If the issue persists please contact Orchid’s Support Team"
    },
    "bank_account_declined": {
        "explanation": "The bank account is not approved or supported for charges",
        "recommended_action": "Use a different payment method or verify the account. If the error remains please contact Orchid’s Support Team"
    },
    "bank_account_exists": {
        "explanation": "The bank account already exists on the record",
        "recommended_action": "Select the existing account or remove duplicates. If needed please contact Orchid’s Support Team"
    },
    "bank_account_restricted": {
        "explanation": "The bank account cannot be used for this payment",
        "recommended_action": "A different payment method is required. Please contact Orchid’s Support Team if no alternative is available"
    },
    "bank_account_unusable": {
        "explanation": "The bank account is unusable",
        "recommended_action": "Use a different bank account or payment option. For questions please contact Orchid’s Support Team"
    },
    "bank_account_unverified": {
        "explanation": "The bank account has not been verified and cannot be used",
        "recommended_action": "Verify the account. If unclear please contact Orchid’s Support Team"
    },
    "bank_account_verification_failed": {
        "explanation": "Bank account verification was unsuccessful",
        "recommended_action": "Check microdeposit amounts or contact Orchid’s Support Team if repeated failures occur"
    },
    "billing_invalid_mandate": {
        "explanation": "The linked mandate is not active",
        "recommended_action": "A valid mandate must be set before charging. Please contact Orchid’s Support Team if needed"
    },
    "bitcoin_upgrade_required": {
        "explanation": "Legacy Bitcoin payment flow is no longer supported",
        "recommended_action": "Upgrade to a newer integration. Contact Orchid’s Support Team for additional info"
    },
    "capture_charge_authorization_expired": {
        "explanation": "The authorization period for capturing this payment has expired",
        "recommended_action": "Initiate a new charge. For questions contact Orchid’s Support Team"
    },
    "capture_unauthorized_payment": {
        "explanation": "The payment was not authorized and cannot be captured",
        "recommended_action": "Authorize the payment properly. If the process is unclear please contact Orchid’s Support Team"
    },
    "card_decline_rate_limit_exceeded": {
        "explanation": "The card has been declined multiple times",
        "recommended_action": "Wait 24 hours or use a different card. If the issue persists please contact Orchid’s Support Team"
    },
    "card_declined": {
        "explanation": "The card was declined",
        "recommended_action": "Try another card or payment method. If unclear please contact Orchid’s Support Team"
    },
    "cardholder_phone_number_required": {
        "explanation": "A phone number is required for this cardholder in specific regions",
        "recommended_action": "Provide a valid phone number. For questions contact Orchid’s Support Team"
    },
    "charge_already_captured": {
        "explanation": "The charge was already captured",
        "recommended_action": "A second capture is not needed. If there are questions contact Orchid’s Support Team"
    },
    "charge_already_refunded": {
        "explanation": "This payment was already refunded",
        "recommended_action": "Multiple refunds are not possible. For additional refunds contact Orchid’s Support Team"
    },
    "charge_disputed": {
        "explanation": "The payment is under dispute",
        "recommended_action": "A refund cannot be processed while a dispute is open. Contact Orchid’s Support Team for dispute guidance"
    },
    "charge_exceeds_source_limit": {
        "explanation": "This payment exceeds the source’s processing limit",
        "recommended_action": "Try again later or consider an alternative method. Contact Orchid’s Support Team about limit increases"
    },
    "charge_exceeds_transaction_limit": {
        "explanation": "The transaction exceeds the set limit",
        "recommended_action": "Use a smaller amount or request a higher limit. If needed please contact Orchid’s Support Team"
    },
    "charge_expired_for_capture": {
        "explanation": "The payment authorization has expired",
        "recommended_action": "A new charge must be created. For questions contact Orchid’s Support Team"
    },
    "charge_invalid_parameter": {
        "explanation": "One or more parameters were invalid for the charge",
        "recommended_action": "Check the fields being passed. If unresolved please contact Orchid’s Support Team"
    },
    "charge_not_refundable": {
        "explanation": "The charge can no longer be refunded",
        "recommended_action": "Review policies or contact Orchid’s Support Team for clarification"
    },
    "clearing_code_unsupported": {
        "explanation": "The clearing code provided is not supported",
        "recommended_action": "Use a valid clearing code for the region. If unclear please contact Orchid’s Support Team"
    },
     "country_code_invalid": {
        "explanation": "The country code is invalid",
        "recommended_action": "Use a proper two-letter country code. If the issue persists please contact Orchid’s Support Team"
    },
    "country_unsupported": {
        "explanation": "The attempted operation is in an unsupported country",
        "recommended_action": "Only supported countries are allowed. Please contact Orchid’s Support Team for details"
    },
    "coupon_expired": {
        "explanation": "The coupon or discount code is expired",
        "recommended_action": "Use a valid coupon or remove the code. If needed please contact Orchid’s Support Team"
    },
    "customer_max_payment_methods": {
        "explanation": "The maximum number of payment methods was reached for this customer",
        "recommended_action": "Detach some methods or contact Orchid’s Support Team for alternatives"
    },
    "customer_max_subscriptions": {
        "explanation": "The customer has reached the maximum number of subscriptions allowed",
        "recommended_action": "Adjust or remove older subscriptions. For help contact Orchid’s Support Team"
    },
    "customer_tax_location_invalid": {
        "explanation": "The customer address is invalid or incomplete for tax purposes",
        "recommended_action": "Include at least a country and postal code. If the issue persists please contact Orchid’s Support Team"
    },
    "debit_not_authorized": {
        "explanation": "The bank reported this debit as unauthorized",
        "recommended_action": "Another payment source is needed. For questions contact Orchid’s Support Team"
    },
    "email_invalid": {
        "explanation": "The email address is not valid",
        "recommended_action": "Enter a properly formatted address. If the error persists please contact Orchid’s Support Team"
    },
    "expired_card": {
        "explanation": "The card is expired",
        "recommended_action": "Use a valid card or another payment method. For additional help contact Orchid’s Support Team"
    },
    "financial_connections_account_inactive": {
        "explanation": "The Financial Connections account is inactive",
        "recommended_action": "Data refresh is not possible. For details contact Orchid’s Support Team"
    },
    "financial_connections_no_successful_transaction_refresh": {
        "explanation": "No successful transaction refresh exists for this account",
        "recommended_action": "A successful refresh is needed. If uncertain contact Orchid’s Support Team"
    },
    "forwarding_api_inactive": {
        "explanation": "The vault and forward API is not accessible for this account or config",
        "recommended_action": "Contact Orchid’s Support Team for possible activation or alternatives"
    },
    "forwarding_api_invalid_parameter": {
        "explanation": "An invalid parameter was passed to the vault or forward API",
        "recommended_action": "Double-check the fields. If unresolved contact Orchid’s Support Team"
    },
    "forwarding_api_retryable_upstream_error": {
        "explanation": "The destination system is offline or unresponsive",
        "recommended_action": "Retry later. If problems persist contact Orchid’s Support Team"
    },
    "forwarding_api_upstream_connection_error": {
        "explanation": "No response was received from the destination endpoint",
        "recommended_action": "Confirm the endpoint is active. If issues continue contact Orchid’s Support Team"
    },
    "forwarding_api_upstream_connection_timeout": {
        "explanation": "The request to the destination endpoint timed out",
        "recommended_action": "Retry later. If timeouts persist contact Orchid’s Support Team"
    },
    "idempotency_key_in_use": {
        "explanation": "The same idempotency key is in use by another request",
        "recommended_action": "Wait for the previous request to finish or use a new key. If unsure contact Orchid’s Support Team"
    },
    "incorrect_address": {
        "explanation": "The card’s billing address does not match",
        "recommended_action": "Review the address details. If failures continue please contact Orchid’s Support Team"
    },
    "incorrect_cvc": {
        "explanation": "The card’s security code is incorrect",
        "recommended_action": "Check the CVC or use another card. If the issue persists contact Orchid’s Support Team"
    },
    "incorrect_number": {
        "explanation": "The card number is incorrect",
        "recommended_action": "Re-enter the card details or use a different method. If unresolved contact Orchid’s Support Team"
    },
    "incorrect_zip": {
        "explanation": "The card’s postal code does not match",
        "recommended_action": "Review the postal code. If the error remains contact Orchid’s Support Team"
    },
    "instant_payouts_config_disabled": {
        "explanation": "Instant Payouts is not configured for this account",
        "recommended_action": "Typically not applicable to patient payments. Contact Orchid’s Support Team if encountered"
    },
    "instant_payouts_currency_disabled": {
        "explanation": "Instant Payouts is not enabled for this currency",
        "recommended_action": "Not usually relevant in this context. Contact Orchid’s Support Team if this error is seen"
    },
    "instant_payouts_limit_exceeded": {
        "explanation": "The daily Instant Payouts limit has been reached",
        "recommended_action": "Not applicable for typical online charges. If shown contact Orchid’s Support Team"
    },
    "instant_payouts_unsupported": {
        "explanation": "Instant Payouts is not available for this request",
        "recommended_action": "If encountered contact Orchid’s Support Team for details"
    },
    "insufficient_funds": {
        "explanation": "The balance or account has insufficient funds for this payment",
        "recommended_action": "Use a different payment source or method. If the issue persists contact Orchid’s Support Team"
    },
    "intent_invalid_state": {
        "explanation": "The Payment or Setup Intent is in an invalid state",
        "recommended_action": "A new payment or re-initialization may be required. If unclear contact Orchid’s Support Team"
    },
    "intent_verification_method_missing": {
        "explanation": "The PaymentMethodOptions do not specify a verification method",
        "recommended_action": "Include the required verification method. If needed contact Orchid’s Support Team"
    },
    "invalid_card_type": {
        "explanation": "The card is not supported for this payout or transaction",
        "recommended_action": "Use a supported card type. If questions arise contact Orchid’s Support Team"
    },
    "invalid_characters": {
        "explanation": "Unsupported characters were found in the input fields",
        "recommended_action": "Remove special characters and retry. If the error continues contact Orchid’s Support Team"
    },
    "invalid_charge_amount": {
        "explanation": "The charge amount is invalid or out of range",
        "recommended_action": "Use a valid positive integer within the allowed range. If the issue persists contact Orchid’s Support Team"
    },
    "invalid_cvc": {
        "explanation": "The card’s security code is invalid",
        "recommended_action": "Check the code or use another card. If the issue remains contact Orchid’s Support Team"
    },
    "invalid_expiry_month": {
        "explanation": "The card’s expiration month is incorrect",
        "recommended_action": "Enter the correct expiration month. If problems persist contact Orchid’s Support Team"
    },
    "invalid_expiry_year": {
        "explanation": "The card’s expiration year is incorrect",
        "recommended_action": "Enter a valid expiration year. If needed contact Orchid’s Support Team"
    },
    "invalid_mandate_reference_prefix_format": {
        "explanation": "The mandate reference prefix is invalid",
        "recommended_action": "Use a proper format. If unsure contact Orchid’s Support Team"
    },
    "invalid_number": {
        "explanation": "The card number is invalid",
        "recommended_action": "Re-check the number or use an alternative card. If unclear contact Orchid’s Support Team"
    },
    "invalid_source_usage": {
        "explanation": "This source is not in a valid state for charging",
        "recommended_action": "Use a valid source or method. For guidance contact Orchid’s Support Team"
    },
    "invalid_tax_location": {
        "explanation": "The address details for tax calculation are invalid",
        "recommended_action": "Ensure the format is correct. For assistance contact Orchid’s Support Team"
    },
    "invoice_no_customer_line_items": {
        "explanation": "No pending items exist for the specified customer invoice",
        "recommended_action": "Add invoice items or confirm the customer ID. If needed contact Orchid’s Support Team"
    },
    "invoice_no_payment_method_types": {
        "explanation": "No valid payment method types are available for this invoice",
        "recommended_action": "Activate or configure payment methods. For questions contact Orchid’s Support Team"
    },
    "invoice_no_subscription_line_items": {
        "explanation": "No pending items exist for this subscription invoice",
        "recommended_action": "Confirm the subscription or add line items. If unclear contact Orchid’s Support Team"
    },
     "invoice_not_editable": {
        "explanation": "This invoice can no longer be edited",
        "recommended_action": "Create new items for the next cycle. For further details contact Orchid’s Support Team"
    },
    "invoice_on_behalf_of_not_editable": {
        "explanation": "The on_behalf_of field cannot be changed after assigning an invoice number",
        "recommended_action": "A new invoice may be needed if changes are required. Contact Orchid’s Support Team for help"
    },
    "invoice_payment_intent_requires_action": {
        "explanation": "The PaymentIntent for this invoice needs additional action",
        "recommended_action": "A user confirmation or authentication step is required. Contact Orchid’s Support Team if unclear"
    },
    "invoice_upcoming_none": {
        "explanation": "There is no upcoming invoice for this customer to preview",
        "recommended_action": "A subscription or pending item is needed. If uncertain contact Orchid’s Support Team"
    },
    "livemode_mismatch": {
        "explanation": "Test and live mode objects are being mixed",
        "recommended_action": "Use consistent API keys or modes. For help contact Orchid’s Support Team"
    },
    "lock_timeout": {
        "explanation": "Another process is accessing this object concurrently",
        "recommended_action": "Retry after the current process completes. If frequent contact Orchid’s Support Team"
    },
    "missing": {
        "explanation": "The source is not attached to the specified customer",
        "recommended_action": "Attach the source to the customer correctly. If the issue persists contact Orchid’s Support Team"
    },
    "no_account": {
        "explanation": "The specified bank account could not be located",
        "recommended_action": "Check the routing or account info or use another payment method. For help contact Orchid’s Support Team"
    },
    "not_allowed_on_standard_account": {
        "explanation": "This operation is restricted on a Standard connected account",
        "recommended_action": "Contact Orchid’s Support Team about account-level permissions"
    },
    "out_of_inventory": {
        "explanation": "One or more line items are out of stock",
        "recommended_action": "Remove or update out-of-stock items. If needed contact Orchid’s Support Team"
    },
    "ownership_declaration_not_allowed": {
        "explanation": "Ownership declaration can only be done during certain account updates",
        "recommended_action": "Contact Orchid’s Support Team for the correct process"
    },
    "parameter_invalid_empty": {
        "explanation": "Required field is empty",
        "recommended_action": "Populate all mandatory fields. If unsure contact Orchid’s Support Team"
    },
    "parameter_invalid_integer": {
        "explanation": "A field that needs an integer received a different type",
        "recommended_action": "Use a valid integer value. If the issue persists contact Orchid’s Support Team"
    },
    "parameter_invalid_string_blank": {
        "explanation": "A string field contains only whitespace",
        "recommended_action": "Use a non-blank value. If unresolved contact Orchid’s Support Team"
    },
    "parameter_invalid_string_empty": {
        "explanation": "A required string field is empty",
        "recommended_action": "Enter valid text. If the error continues contact Orchid’s Support Team"
    },
    "parameter_missing": {
        "explanation": "A required parameter is missing from the request",
        "recommended_action": "Refer to documentation. If unsure contact Orchid’s Support Team"
    },
    "parameter_unknown": {
        "explanation": "An unexpected parameter is present in the request",
        "recommended_action": "Remove unrecognized fields. If confusion remains contact Orchid’s Support Team"
    },
    "parameters_exclusive": {
        "explanation": "Two or more mutually exclusive parameters were provided",
        "recommended_action": "Use only the allowed combination. For details contact Orchid’s Support Team"
    },
    "payment_intent_action_required": {
        "explanation": "Additional user action is needed but the system is set to fail in such cases",
        "recommended_action": "A flow supporting authentication is required. For help contact Orchid’s Support Team"
    },
    "payment_intent_authentication_failure": {
        "explanation": "The provided payment method failed authentication",
        "recommended_action": "Use another method or re-authenticate. If the issue remains contact Orchid’s Support Team"
    },
    "payment_intent_incompatible_payment_method": {
        "explanation": "The PaymentIntent expected a different payment method type",
        "recommended_action": "Select a compatible payment method. For questions contact Orchid’s Support Team"
    },
    "payment_intent_invalid_parameter": {
        "explanation": "One or more parameters for the PaymentIntent were invalid",
        "recommended_action": "Adjust the request fields. If unclear contact Orchid’s Support Team"
    },
    "payment_intent_konbini_rejected_confirmation_number": {
        "explanation": "The konbini confirmation number was rejected",
        "recommended_action": "Follow local format guidelines. If unresolved contact Orchid’s Support Team"
    },
    "payment_intent_mandate_invalid": {
        "explanation": "The mandate is not valid for this PaymentIntent",
        "recommended_action": "Use a correct mandate. For assistance contact Orchid’s Support Team"
    },
    "payment_intent_payment_attempt_expired": {
        "explanation": "The latest payment attempt on this PaymentIntent has expired",
        "recommended_action": "A new payment method is needed. If the error persists contact Orchid’s Support Team"
    },
    "payment_intent_payment_attempt_failed": {
        "explanation": "The latest payment attempt failed",
        "recommended_action": "Check the error details and use a different method. Contact Orchid’s Support Team if needed"
    },
    "payment_intent_unexpected_state": {
        "explanation": "The PaymentIntent is not in the correct state for the requested operation",
        "recommended_action": "A new PaymentIntent may be necessary. If unclear contact Orchid’s Support Team"
    },
    "payment_method_bank_account_already_verified": {
        "explanation": "The bank account is already verified",
        "recommended_action": "No further verification needed. For questions contact Orchid’s Support Team"
    },
    "payment_method_bank_account_blocked": {
        "explanation": "This bank account is blocked due to past failures",
        "recommended_action": "Use a different account or method. If confusion remains contact Orchid’s Support Team"
    },
    "payment_method_billing_details_address_missing": {
        "explanation": "The billing address is missing for this PaymentMethod",
        "recommended_action": "Provide complete address details. If the error persists contact Orchid’s Support Team"
    },
    "payment_method_configuration_failures": {
        "explanation": "Could not create or update the Payment Method configuration",
        "recommended_action": "Indicates a setup issue. Contact Orchid’s Support Team for resolution"
    },
    "payment_method_currency_mismatch": {
        "explanation": "The currency does not match the PaymentMethod’s currency",
        "recommended_action": "Use the same currency or a different method. Contact Orchid’s Support Team if needed"
    },
    "payment_method_customer_decline": {
        "explanation": "The cardholder declined the payment",
        "recommended_action": "Select another payment method. If unclear contact Orchid’s Support Team"
    },
    "payment_method_invalid_parameter": {
        "explanation": "Invalid parameters were passed in the PaymentMethod object",
        "recommended_action": "Check Stripe docs. If the error continues contact Orchid’s Support Team"
    },
    "payment_method_invalid_parameter_testmode": {
        "explanation": "A restricted parameter was used in test mode",
        "recommended_action": "Remove or change this parameter. If confusion remains contact Orchid’s Support Team"
    },
    "payment_method_microdeposit_failed": {
        "explanation": "Microdeposits did not arrive in the bank account",
        "recommended_action": "Verify the account info or use a different payment method. Contact Orchid’s Support Team if needed"
    },
    "payment_method_microdeposit_verification_amounts_invalid": {
        "explanation": "Exactly two microdeposit amounts are required",
        "recommended_action": "Provide two amounts for verification. If repeated failures occur contact Orchid’s Support Team"
    },
    "payment_method_microdeposit_verification_amounts_mismatch": {
        "explanation": "The microdeposit amounts provided do not match the deposited amounts",
        "recommended_action": "Check the statement. If mismatch persists contact Orchid’s Support Team"
    },
    "payment_method_microdeposit_verification_attempts_exceeded": {
        "explanation": "Too many verification attempts have failed",
        "recommended_action": "A different approach or account is necessary. If unclear contact Orchid’s Support Team"
    },
    "payment_method_microdeposit_verification_descriptor_code_mismatch": {
        "explanation": "The verification code does not match the descriptor code from the bank statement",
        "recommended_action": "Check the code from the statement. If confusion remains contact Orchid’s Support Team"
    },
    "payment_method_microdeposit_verification_timeout": {
        "explanation": "Microdeposit verification was not completed in time",
        "recommended_action": "Re-initiate or use another method. For assistance contact Orchid’s Support Team"
    },
    "payment_method_not_available": {
        "explanation": "That payment method is temporarily unavailable",
        "recommended_action": "Use a different method or try again later. If unavailable contact Orchid’s Support Team"
    },
    "payment_method_provider_decline": {
        "explanation": "The payment attempt was declined by the issuer or provider",
        "recommended_action": "Choose another method. If more details are needed contact Orchid’s Support Team"
    },
    "payment_method_provider_timeout": {
        "explanation": "The provider did not respond before timing out",
        "recommended_action": "Retry or use a different method. If problems continue contact Orchid’s Support Team"
    },
    "payment_method_unactivated": {
        "explanation": "The payment method is not activated yet",
        "recommended_action": "Activation is required before use. Please contact Orchid’s Support Team for setup"
    },
    "payment_method_unexpected_state": {
        "explanation": "The payment method is not in a valid state for this operation",
        "recommended_action": "Use a valid method. If questions arise contact Orchid’s Support Team"
    },
    "payment_method_unsupported_type": {
        "explanation": "This payment method type is not supported by the API",
        "recommended_action": "Select a supported method. If unclear contact Orchid’s Support Team"
    },
    "payout_reconciliation_not_ready": {
        "explanation": "Reconciliation for this payout is still in progress",
        "recommended_action": "Typically not relevant here. If shown contact Orchid’s Support Team"
    },
    "payouts_limit_exceeded": {
        "explanation": "The daily payout limit has been reached",
        "recommended_action": "Not usually patient-related. Contact Orchid’s Support Team if encountered"
    },
    "payouts_not_allowed": {
        "explanation": "Payouts have been disabled for this account",
        "recommended_action": "If encountered contact Orchid’s Support Team"
    },
    "platform_account_required": {
        "explanation": "A Connect platform is required for this operation",
        "recommended_action": "Contact Orchid’s Support Team if this error appears"
    },
    "platform_api_key_expired": {
        "explanation": "The platform’s API key has expired",
        "recommended_action": "Obtain updated keys or contact Orchid’s Support Team for help"
    },
    "postal_code_invalid": {
        "explanation": "The provided postal code is invalid",
        "recommended_action": "Enter a valid postal code. If the error persists contact Orchid’s Support Team"
    },
    "processing_error": {
        "explanation": "An unspecified error occurred while processing the card",
        "recommended_action": "Retry or use another method. If it continues contact Orchid’s Support Team"
    },
    "product_inactive": {
        "explanation": "The product is no longer available for purchase",
        "recommended_action": "Use an active product or re-activate it. If needed contact Orchid’s Support Team"
    },
    "progressive_onboarding_limit_exceeded": {
        "explanation": "The limit for progressive onboarding has been reached",
        "recommended_action": "Contact Orchid’s Support Team if shown"
    },
    "rate_limit": {
        "explanation": "Too many requests were made too quickly",
        "recommended_action": "Wait briefly and retry. If repeated contact Orchid’s Support Team"
    },
    "refer_to_customer": {
        "explanation": "The payment was stopped at the cardholder’s request",
        "recommended_action": "A different method may be required. If needed contact Orchid’s Support Team"
    },
    "refund_disputed_payment": {
        "explanation": "A disputed payment cannot be refunded",
        "recommended_action": "Resolve the dispute first. For guidance contact Orchid’s Support Team"
    },
    "resource_already_exists": {
        "explanation": "A resource with this ID already exists",
        "recommended_action": "Use a unique ID or retrieve the existing resource. If confusion remains contact Orchid’s Support Team"
    },
    "resource_missing": {
        "explanation": "The provided ID does not match an existing resource",
        "recommended_action": "Confirm the correct ID. If the issue continues contact Orchid’s Support Team"
    },
    "return_intent_already_processed": {
        "explanation": "The refund has already been processed",
        "recommended_action": "Another refund cannot be confirmed. For additional refunds contact Orchid’s Support Team"
    },
    "routing_number_invalid": {
        "explanation": "The bank routing number is invalid",
        "recommended_action": "Check the routing format. If problems persist contact Orchid’s Support Team"
    },
    "secret_key_required": {
        "explanation": "A secret key was required but only a publishable key was used",
        "recommended_action": "Contact Orchid’s Support Team to update key usage"
    },
    "sepa_unsupported_account": {
        "explanation": "The account does not support SEPA payments",
        "recommended_action": "If SEPA is needed contact Orchid’s Support Team for options"
    },
    "setup_attempt_failed": {
        "explanation": "The latest SetupIntent attempt failed",
        "recommended_action": "Use a different payment method or fix the issue. If unresolved contact Orchid’s Support Team"
    },
    "setup_intent_authentication_failure": {
        "explanation": "The payment method could not be authenticated for setup",
        "recommended_action": "Another method or re-authentication may be needed. For help contact Orchid’s Support Team"
    },
    "setup_intent_invalid_parameter": {
        "explanation": "Invalid parameters were provided for the SetupIntent",
        "recommended_action": "Adjust the fields. If confusion persists contact Orchid’s Support Team"
    },
    "setup_intent_mandate_invalid": {
        "explanation": "The mandate is not valid for this SetupIntent",
        "recommended_action": "Provide a correct mandate. If needed contact Orchid’s Support Team"
    },
    "setup_intent_mobile_wallet_unsupported": {
        "explanation": "Mobile wallets are not supported for certain SetupIntents",
        "recommended_action": "A physical card may be required. If questions arise contact Orchid’s Support Team"
    },
    "setup_intent_setup_attempt_expired": {
        "explanation": "The setup attempt expired before completion",
        "recommended_action": "Re-initiate with a new method. For assistance contact Orchid’s Support Team"
    },
    "setup_intent_unexpected_state": {
        "explanation": "The SetupIntent is not in a valid state for this operation",
        "recommended_action": "A new SetupIntent may be necessary. If unclear contact Orchid’s Support Team"
    },
    "shipping_address_invalid": {
        "explanation": "The shipping address could not be used for tax or shipping calculation",
        "recommended_action": "Verify the address fields. If problems persist contact Orchid’s Support Team"
    },
    "shipping_calculation_failed": {
        "explanation": "Shipping fees could not be calculated with the provided info",
        "recommended_action": "Double-check the shipping details. If unresolved contact Orchid’s Support Team"
    },
    "sku_inactive": {
        "explanation": "The SKU is inactive and cannot be purchased",
        "recommended_action": "Enable or use an active SKU. If the issue persists contact Orchid’s Support Team"
    },
    "state_unsupported": {
        "explanation": "The provided U.S. state or territory is not supported for a custom account",
        "recommended_action": "If shown contact Orchid’s Support Team"
    },
    "status_transition_invalid": {
        "explanation": "The requested status change is not valid",
        "recommended_action": "Use a permitted transition. If unclear contact Orchid’s Support Team"
    },
    "stripe_tax_inactive": {
        "explanation": "Stripe Tax is not activated on this account",
        "recommended_action": "If the error appears contact Orchid’s Support Team"
    },
    "tax_id_invalid": {
        "explanation": "The provided tax ID is invalid",
        "recommended_action": "Ensure correct formatting for the country. If needed contact Orchid’s Support Team"
    },
    "taxes_calculation_failed": {
        "explanation": "Tax calculation could not be completed",
        "recommended_action": "Check the address or transaction details. If unresolved contact Orchid’s Support Team"
    },
    "testmode_charges_only": {
        "explanation": "The account is in test mode and cannot process live payments",
        "recommended_action": "Activate the account for live mode. If the error persists contact Orchid’s Support Team"
    },
    "tls_version_unsupported": {
        "explanation": "An older version of TLS is being used",
        "recommended_action": "Upgrade to TLS 1.2 or higher. If issues remain contact Orchid’s Support Team"
    },
    "token_already_used": {
        "explanation": "The token has already been used",
        "recommended_action": "Create a new token for this transaction. For questions contact Orchid’s Support Team"
    },
    "token_card_network_invalid": {
        "explanation": "An invalid card network parameter was included in the token",
        "recommended_action": "Use valid network parameters. If confusion persists contact Orchid’s Support Team"
    },
    "token_in_use": {
        "explanation": "The token is already in use by another request",
        "recommended_action": "Wait or create a new token. If unsure contact Orchid’s Support Team"
    },
    "transfer_source_balance_parameters_mismatch": {
        "explanation": "Source balance parameters cannot be used together for this transfer",
        "recommended_action": "Contact Orchid’s Support Team if it appears"
    },
    "transfers_not_allowed": {
        "explanation": "This transfer is disallowed by Stripe",
        "recommended_action": "Contact Orchid’s Support Team for clarification"
    },
    "url_invalid": {
        "explanation": "The provided URL is not valid",
        "recommended_action": "Use a properly formatted URL. If the error continues contact Orchid’s Support Team"
    }
}

export const getCardDeclineErrorMessage = (decline_code) => {
    const entry = card_decline_codes[decline_code];
    if (entry) {
        return `${entry.explanation}. ${entry.recommended_action}`;
    }
    return null;
}

export const getStripeErrorMessage = (error_code) => {
    const entry = stripe_error_codes[error_code];
    if (entry) {
        return `${entry.explanation}. ${entry.recommended_action}`;
    }
    return null;
}
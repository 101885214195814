import { useState, useMemo } from "react";
import {
  Form,
  Button,
  InputGroup,
  Alert,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";
import Checkbox from "components/checkbox/Checkbox";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";
import { useAuthModals } from "contexts/auth-modals-context";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import completeSignup from "services/client-portal/completeSignup";

type Props = {
  isAdultCheckId: string;
  isInModal?: boolean;
};
const MemberRegisterForm = ({ isAdultCheckId, isInModal }: Props) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const { setCustomEmailSentModalProps, openEmailSentModal } = useAuthModals();
  const location = useLocation();

  const { completeSignupToken } = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return {
      completeSignupToken: query.get("complete_signup_token") || "",
    };
  }, [location.search]);

  const [state, setState] = useState({
    first_name: "",
    email: "",
    password: "",
    invite_code:
      new URLSearchParams(location.search).get("invite_code") || null,
    patient_chart_invite_code:
      new URLSearchParams(location.search).get("patient_chart_invite_code") ||
      null,
    busy: false,
    revealPassword: false,
    is_over_eighteen: undefined,
    error: "",
    showUnmetPasswordRequirement: false,
    validated: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    window.localStorage.setItem(
      "redirect_after_register",
      window.location.pathname + window.location.search + window.location.hash,
    );
    console.log(window.localStorage.getItem("redirect_after_register"));

    const form = event.currentTarget;
    if (!form.checkValidity()) {
      setState({
        ...state,
        validated: true,
        error: "Please fill out all of the required fields correctly",
        showUnmetPasswordRequirement: true,
      });
      return;
    }

    setState({ ...state, busy: true });

    if (completeSignupToken) {
      try {
        await completeSignup(completeSignupToken, state.password);
        setCustomEmailSentModalProps({
          email: state.email,
        });
        openEmailSentModal();
      } catch (error: any) {
        setState({ ...state, error: error });
      }
    } else {
      try {
        const response = await fetchOrchidAPI("/api/users/v1/patient/account", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user_in: {
              email: state.email,
              password: state.password,
              invite_code: state.invite_code,
              patient_chart_invite_code: state.patient_chart_invite_code,
            },
            patient_in: {
              first_name: state.first_name,
              is_over_eighteen: state.is_over_eighteen,
            },
          }),
        });
        if (!response.ok) {
          setState({
            ...state,
            error: `Failed to register: ${getErrorDetail(
              await response.text(),
            )}`,
            busy: false,
          });
          return;
        }
        setCustomEmailSentModalProps({
          email: state.email,
        });
        openEmailSentModal();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const onInputChange = (event) => {
    setState({
      ...state,
      [event.target.id]: event.target.value,
    });
  };

  const onAdultChecked = (event) => {
    setState({ ...state, is_over_eighteen: event.currentTarget.checked });
  };

  const togglePassword = (event) => {
    event.preventDefault();
    setState({ ...state, revealPassword: !state.revealPassword });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validated={state.validated}
      className={getClasses(isPhonePortrait && styles["auth-form--phone"])}
      noValidate
    >
      {completeSignupToken ? null : !state.patient_chart_invite_code ? (
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="first_name">
            {!isPhonePortrait && (
              <Form.Label className="rg-12 gray01">First name</Form.Label>
            )}
            <Form.Control
              required
              type="text"
              placeholder="Enter first name"
              value={state.first_name}
              onChange={onInputChange}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="email">
            {!isPhonePortrait && (
              <Form.Label className="rg-12 gray01">Email</Form.Label>
            )}
            <Form.Control
              required
              type="email"
              placeholder="Enter email"
              value={state.email}
              onChange={onInputChange}
            />
          </Form.Group>
        </Form.Row>
      ) : (
        <Form.Group controlId="first_name">
          {!isPhonePortrait && (
            <Form.Label className="rg-12 gray01">First name</Form.Label>
          )}
          <Form.Control
            required
            type="text"
            placeholder="Enter first name"
            value={state.first_name}
            onChange={onInputChange}
          />
        </Form.Group>
      )}
      <Form.Group controlId="password">
        {!isPhonePortrait && (
          <Form.Label className="rg-12 gray01">Password</Form.Label>
        )}
        <InputGroup
          className={getClasses(
            "align-items-center mb-2",
            styles["password"],
            isPhonePortrait && styles["password--phone"],
          )}
        >
          <Form.Control
            required
            type={state.revealPassword ? "text" : "password"}
            placeholder="Enter password"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^=+$\-*.[\]{}()?&quot;!@#%&/\\,><':;|_~`])[\^A-Za-z\d=+$\-*.[\]{}()?&quot;!@#%&/\\,><':;|_~`]{10,}$"
            value={state.password}
            onChange={onInputChange}
          />
          <InputGroup.Append onClick={togglePassword}>
            <InputGroup.Text>
              {state.revealPassword ? "Hide" : "Show"}
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>
      <div className={`light-gray fs-12 ${state.password ? null : "d-none"}`}>
        <ul
          className={`px-0 ${
            state.showUnmetPasswordRequirement ? "red" : null
          }`}
        >
          <Row>
            <Col md={5}>
              <li
                className={
                  state.password.toLowerCase() !== state.password
                    ? "text-success"
                    : ""
                }
              >
                1 uppercase letter
              </li>
              <li
                className={
                  state.password.toUpperCase() !== state.password
                    ? "text-success"
                    : ""
                }
              >
                1 lowercase letter
              </li>
              <li
                className={
                  /[\^=+$\-*.[\]{}()?"!@#%&\\,><':;|_~`]/.test(state.password)
                    ? "text-success"
                    : ""
                }
              >
                1 special character
              </li>
            </Col>
            <Col md={5}>
              <li className={/\d/.test(state.password) ? "text-success" : ""}>
                1 number
              </li>
              <li className={state.password.length >= 10 ? "text-success" : ""}>
                10 characters or more
              </li>
            </Col>
          </Row>
        </ul>
      </div>
      <Checkbox
        required
        id={isAdultCheckId}
        onChange={onAdultChecked}
        label={
          <label className="fs-14">
            I am over 18 and agree to the{" "}
            <Link to="/terms-of-use" target="_blank">
              Terms of Use
            </Link>{" "}
            and{" "}
            <Link to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
          </label>
        }
        className="mb-0"
      />
      {state.error && (
        <Alert variant="danger" className="m-0">
          {state.error}
        </Alert>
      )}
      <Button
        disabled={state.busy}
        variant="primary"
        type="submit"
        className={getClasses(
          isInModal && "w-100",
          "my-3",
          isPhonePortrait && styles["auth-button--phone"],
        )}
      >
        Register{" "}
        {state.busy && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
    </Form>
  );
};

export default MemberRegisterForm;
